.cardFeature__container {
    width: 100%;
    height: auto;
    margin: 4px 0;

    display: grid;
    grid-template-columns: 7fr 5fr;
    grid-template-rows: auto;
    align-items: center;
}

.cardFeature__container--fullwidth {
    width: 100%;
    height: auto;
    margin: 4px 0;

    display: grid;
    align-items: center;
}

.cardFeature__main {
    width: 100%;
    height: auto;

    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.cardFeature__icon {
    width: 24px;
    height: 24px;
    color: var(--color-tertiary);
    stroke-width: 2px;
}

.cardFeature__text {
    font-size: var(--fs-small);
    line-height: var(--fs-small);
    font-weight: 500;
    color: var(--color-text-secondary);
    margin-left: 8px;
}

.cardFeature__description {
    font-size: var(--fs-tiny);
    line-height: var(--fs-tiny);
    font-weight: 500;
    color: var(--color-text-secondary);
    opacity: 0.75;
    text-align: right;
}

@media screen and (max-width: 768px) {
    .cardFeature__container {
        margin: 8px 0;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
    }
    .cardFeature__description {
        text-align: left;
        margin-left: 32px; /*24px Icon width + 8px text margin-left*/
    }
   .cardFeature__text,.cardFeature__description{
      color: var(--color-text-primary);
   }
}