.coursesFilter-father-div {
  width: auto;
  z-index: 2;
  box-shadow: var(--box-shadow-md);
  border-radius: var(--border-radius-lg);

  background-image: url('../../../assets/fondo-web-forvet.svg');
  background-position: left top;
  background-size: contain;
  background-repeat: repeat;
  background-color: #efefef;
}

.coursesFilter-filter-div-container {
  display: flex;
  flex-direction: column;
  
  border-radius: 8px;
  padding: 16px 24px;
  /* outline: 1px solid var(--color-primary-lightest); */
  
}

.coursesFilter-span-styling {
  padding-right: 8px;
  font-weight: 700;
  font-size: var(--fs-small);
  color: var(--color-text-primary);
}

.coursesFilter-second-span-styling {
  padding-right: 8px;
  padding-left: 8px;
  font-weight: 700;
  font-size: var(--fs-small);
  color: var(--color-text-primary);
}


.courserFilter-select-option-customization:first-child{
   border-top-left-radius: var(--border-radius-lg) !important;
   border-top-right-radius: var(--border-radius-lg) !important;

}

.courserFilter-select-option-customization:last-child{
   border-bottom-left-radius: var(--border-radius-lg) !important;
   border-bottom-right-radius: var(--border-radius-lg) !important;
}

.courserFilter-dropdown-option-customization{
   padding: 12px 8px !important;
}

.courserFilter-select-wrapper-customization .mantine-CloseButton-root {
   color: var(--color-text-primary) ;
}


@media screen and (max-width: 768px) {
  .coursesFilter-father-div {
    width: 80%;
  }

  .coursesFilter-filter-div-container {
    width: 100%;
  }

  .coursesFilter-span-styling {
    padding-bottom: 8px;
    font-weight: 500;
    font-size: var(--fs-small);
  }
  .coursesFilter-second-span-styling {
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 500;
    font-size: var(--fs-small);
  }

  .coursesFilter-button-mobile-styling {
    padding-top: 8px;
  }

  .courserFilter-select-wrapper-customization {
    width: 100%;
  }
}
