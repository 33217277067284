.promotionModal__title {
    font-family: var(--font-primary);
    font-size: var(--fs-h3) !important;
    line-height: calc(var(--fs-h3) * 1.2);
    font-weight: 700;
    color: var(--color-text-primary);
    text-align: center;
}

.promotionModal__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: auto;
    margin: 16px auto;
    max-width: 80%;
}

.promotionModal__container--row {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    width: auto;
    margin: 24px 16px 0;
}

.promotionModal__image {
    width: 100%;
    height: auto;
    border-radius: var(--border-radius-md);
}