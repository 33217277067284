.mega-menu-iconCard-container-link {
   display: flex;
   /* justify-content: flex-start; */
   justify-content: center;
   flex-direction: column;
   align-items: center;
   text-decoration: none;
   color: var(--color-black);
   gap: 8px;
   background-color: var(--color-secondary);
   border-radius: var(--border-radius-lg);
   padding: 5px;
   width: 100%;
   height: 100%;
   box-shadow: var(--box-shadow);
}
/* curso */
.mega-menu-iconCard-container-link:first-child {
   grid-row: 2/4;
   grid-column: 1/2;
}

/* posgado */
.mega-menu-iconCard-container-link:nth-child(2) {
   grid-row: 1/3;
   grid-column: 3/4;
}
/* diplomado */
.mega-menu-iconCard-container-link:nth-child(3) {
   grid-row: 1/2;
   grid-column: 1/3;
}

/* masterclass */
.mega-menu-iconCard-container-link:nth-child(4) {
   grid-row: 3/4;
   grid-column: 2/4;
}

/* webinar */
.mega-menu-iconCard-container-link:last-child {
   grid-row: 2/3;
   grid-column: 2/3;
}

.mega-menu-iconCard-container-link:hover {
   opacity: .7;
}

.mega-menu-iconCard-image-container {
   height: 56px;
   width: 56px;
   display: grid;
   place-items: center;
}

.mega-menu-iconCard-icon {
   height: 100%;
   width: 100%;
   object-fit: cover;
   color: var(--color-text-secondary);
}

.mega-menu-iconCard-content {
   display: flex;
   align-items: center;
}

.mega-menu-iconCard-link-text {
   font-size: var(--fs-body);
   text-transform: capitalize;
   color: var(--color-text-secondary);
}