.paymentPageLogo-imageOverShape-container {
    position: relative;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    box-shadow: 0px 15px 10px -15px #0000000F;
    border-radius: 20px;
}

.paymentPageLogo-imageOverShape-shape {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--color-secondary);
    border-radius: 50%;
    
}

.paymentPageLogo-imageOverShape-image {
    position: absolute;
    bottom: 0px;
    height: 100%;
    width: auto;
    object-fit: contain;
    filter: drop-shadow(8px 0 8px var(--color-black-transparent));
}


/* cancel image styling down below */

.paymentPageLogo-imageOverShapeCancel-container {
    position: relative;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    box-shadow: 0px 15px 10px -15px #0000000F;
    border-radius: 20px;
}

.paymentPageLogo-imageOverShapeCancel-shape {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--color-red--light);
}

.paymentPageLogo-imageOverShapeCancel-image {
    position: absolute;
    right: -12px;
    top: 27px;
    height: 100%;
    width: auto;
    object-fit: contain;

}
