.cardInfo-row {
   padding-top: 15px !important;
   width: 100%;
   background-color: var(--color-primary-light) !important;
   box-shadow: var(--box-shadow);
}

.cardInfo-row .cardInfo-imageAndtext-container {
   display: flex;
   height: 100%;
   width: 100%;
   gap: 10px;
}

.cardInfo-row .card-info-title {
   font-size: var(--fs-h5);
   text-transform: uppercase;
}

.cardInfo-row .cardInfo-description-container {
   height: 100%;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   gap: 20px;
}

.cardInfo-description-badge-tag {
   background-color: var(--color-tertiary) !important;
}

.cardInfo-description-badge-type {
   background-color: var(--color-secondary) !important;
}

.cardInfo-row .card-items-info--group {
   display: flex;
   align-items: center;
   gap: 5px;
}

.cardInfo-row .card-items-info--group span {
   font-size: var(--fs-small);
   color: var(--color-text-secondary);
}

.cardInfo-row .cardInfo-image-container {
   display: flex;
   align-items: stretch;
   justify-content: stretch;
   width: 50%;
}

.cardInfo-image {
   width: 100%;
   background-repeat: no-repeat;
   background-size: 100% 100%;
   /* border-radius: var(--border-radius-lg) !important; */
   object-fit: cover;
}

.card-items-icon {
   color: var(--color-secondary);
   flex-shrink: 0;
}

.cardInfo-column .text-overflow-ellipsis {
   text-wrap: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
}
