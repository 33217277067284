.courseTab-tabs-div-container{
    width: 100%;
    height: auto;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 20px;
}

.courseTab-Onclick-prop-active-status{
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 20px;
   padding-top: 96px;
}

.courseTab-root-tabs {
  width: 100%;
}

.courseTab-tabLabel-tabs {
   color: var(--color-text-primary);
  font-size: var(--fs-h3);
}

.courseTab--tab:hover .courseTab-tabLabel-tabs {
   color: var(--color-text-secondary);
}
