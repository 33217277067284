
.BlogCard-latest-container {
   height: 100%;
   width: 100%;
}


.BlogCard-latest-container:nth-child(n+2) .BlogCard-latest-wrapper {
   width: 100%;
   display: grid;
   grid-template-columns: 40% 1fr;
   gap: 16px;
   padding: 20px 0;
   border-bottom: 1px solid var(--color-primary-light);

   .Blogcard-latest-description {
      /* display: none; */
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 2;
      line-clamp: 2;
   }

   .BlogCard-latest-image {
      width: 100%;
      height: 200px;
      object-fit: cover;
   }

   .BlogCard-latest--title {
      font-size: var(--fs-h4);
   }
}

.BlogCard-latest-container:nth-child(1) {
   grid-area: blog-1;

   .BlogCard-latest-image {
      max-height: 400px;
      width: 100%;
      object-fit: cover;
   }
}

.BlogCard-latest-container:nth-child(2) {
   grid-area: blog-2;

   .BlogCard-latest-wrapper {
      padding: 0 0 20px 0;

   }
}

.BlogCard-latest-container:nth-child(3) {
   grid-area: blog-3;
}

.BlogCard-latest-container:nth-child(4) {
   grid-area: blog-4;

   .BlogCard-latest-wrapper {
      border-bottom: 0;
   }
}

.BlogCard-latest-image {
   box-shadow: var(--box-shadow);
   border-radius: var(--border-radius-lg);
}

.BlogCard-latest-contentWrapper {
   display: flex;
   flex-direction: column;
   gap: 8px;
}


.BlogCard-latest--date {
   color: var(--color-text-primary);
   opacity: .5;
   font-size: var(--fs-small);
}

.BlogCard-latest--title {
   font-size: var(--fs-h3);
   color: var(--color-text-primary);
}

.Blogcard-latest-description {
   font-size: var(--fs-body);
   color: var(--color-text-primary);
   opacity: .7;
}



.BlogCard-general-container {
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 16px;
}

.BlogCard-general-image {
   width: 100%;
   height: auto;
   border-radius: var(--border-radius-lg);
   object-fit: cover;
   max-height: 200px;
   box-shadow: var(--box-shadow);
}

.BlogCard-general--title {
   font-size: var(--fs-h4);
   color: var(--color-text-primary);
}

.Blogcard-general-description {
   font-size: var(--fs-small);
   color: var(--color-text-primary);
   opacity: .7;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   overflow: hidden;
   -webkit-line-clamp: 3;
   line-clamp: 3;
}

.BlogCard-latest-container:hover,
.BlogCard-general-container:hover {
   opacity: .7;
}

@media (max-width: 768px) {
   .BlogCard-latest-container:nth-child(1) {
      padding-bottom: 20px;
      border-bottom: 1px solid var(--color-primary-light);
   }

   
}