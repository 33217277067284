.BlogDetails-main-container {
   display: flex;
   flex-direction: column;
   gap: 32px;
   width: 100%;
   height: 100%;
   min-height: 70vh;
   overflow-x: hidden;
}

.BlogDetails-content-wrapper {
   display: flex;
   flex-direction: column;
   width: 100%;
   height: 100%;
   flex-grow: 1;
   gap: 32px;
   padding: var(--layout-padding-x) var(--layout-padding-x);
}

.BlogDetails--image {
   height: 400px;
   width: auto;
   max-width: 400px;
   object-fit: cover;
}