.mega-menu-section-formFilter {
   display: flex;
   width: 100%;
   flex-direction: column;
   gap: 20px;
   padding-left: 20px;
   border-left: 1px solid var(--color-secondary);
}

.mega-menu-filterGroup {
   position: relative;
   border-radius: var(--border-radius-lg);
   width: 100%;
   height: 100%;
   transition: all .3s;
}

.mega-menu-filter-content {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;

}

.mega-menu-filterGroup:hover {
   opacity: 0.7;
   transition: all .3s;
}

.mega-menu-filter-text {
   color: var(--color-text-primary);
   font-weight: 400;
   text-align: center;
   width: 100%;
   font-size: var(--fs-body);
   line-height: calc(var(--fs-body)*1.2);
   text-transform: capitalize;
}

.mega-menu-filter-input {
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   width: 100%;
   opacity: 0;
   cursor: pointer;
   z-index: 10;
}

/* Esta regla aplica estilos cuando el input tipo radio está chequeado */
.mega-menu-filter-input[type="radio"]:checked ~ .mega-menu-filter-content {
   color: var(--color-text-secondary);
   background-color: var(--color-primary);
   border-radius: var(--border-radius-lg);
   box-shadow: var(--box-shadow-md);
}

/* Estos estilos aplican a elementos dentro de .mega-menu-filter-content */
/* .mega-menu-filter-input[type="radio"]:checked ~ .mega-menu-filter-content .mega-menu-filter-icon {
   color: var(--color-text-secondary);
} */

.mega-menu-filter-input[type="radio"]:checked ~ .mega-menu-filter-content .mega-menu-filter-description {
   color: var(--color-text-secondary);
}

.mega-menu-filter-input[type="radio"]:checked ~ .mega-menu-filter-content .mega-menu-filter-text {
   color: var(--color-text-secondary);
}


.mega-menu-filter-description {
   font-size: 12px;
   line-height: 14px;
   /* font-size: var(--fs-small); */
   text-align: center;
   margin-top: 4px;
   color: var(--color-text-primary);
}

.mega-menu-filter-icon {
   height: 40px;
   width: 40px;
   object-fit: cover;
   color: var(--color-secondary);
}

@media screen and (max-width: 768px) {
   .mega-menu-section-formFilter {
      flex-direction: column;
      max-height: unset;
   }

   .mega-menu-filterGroup:first-child {
      border-top-left-radius: unset;
   }

   .mega-menu-filterGroup:first-child .mega-menu-filter-text {
      border-top-left-radius: unset;
      border-right: unset;
   }

   .mega-menu-filterGroup:last-child {
      border-top-right-radius: unset;
      border-left: unset;
   }

   .mega-menu-filterGroup:last-child .mega-menu-filter-text {
      border-top-right-radius: unset;
   }

   .mega-menu-filter-text {
      justify-content: flex-start;
      height: 100%;
      width: 100%;
      padding: 8px 12px;
      border-bottom: unset;
   }

   .mega-menu-filter-input[type="radio"]:checked~span {
      background-color: unset;
      color: unset;
   }

   .mega-menu-filter-icon {
      height: 30px;
      width: 30px;
   }
}