@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Xanh+Mono:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap');

:root {
   --color-background: #FCFCFC;

   /*PADDING & MARGIN*/
   --px: 80px;
   --py: 60px;
   --mt: 20px;
   --mb: 20px;
   --layout-padding-x: 48px;
   --layout-padding-y: 32px;

   --box-shadow: 0px 6px 16px 0px #0000000F;
   --box-shadow-md: 1px 1px 4px #000000b6;
   --box-shadow-strong: 0px 0px 6px #00000063;
   --maxWidthContainer: 1600px;
   --font-primary: 'Montserrat', sans-serif;
   --font-secondary: "Archivo", sans-serif;
   --font-tertiary: "Xanh Mono", monospace;
   --headerHeight: 120px;

   /*TODO: REPLACE THIS*/
   --gray: #868686;
   --gray-normal: #505050;
   --gray-1: #DEE2E6;
   --blue-gray: #F1F5F9;

   /*BORDER RADIUS*/
   /* --border-radius-lg: 1.5rem; */
   --border-radius-lg: 12px;

   --border-radius-md: 1rem;
   --border-radius-sm: 0.8rem;

   /*FONT-SIZES HEADING*/
   --fs-h1: 64px;
   --fs-h2: 40px;
   --fs-h3: 24px;
   --fs-h4: 20px;
   --fs-h5: 18px;
   --fs-h6: 16px;
   /*FONT-SIZES BODY*/
   --fs-body: 18px;
   --fs-small: 16px;
   --fs-tiny: 12px;

}

.dark-theme {
   /*COLORS*/
   /* --color-primary-lightest: #477283;
   --color-primary-light: #3D6271;
   --color-primary-dark: #2c4b58;
   --color-primary-darkest: #243D48; */

   --color-primary-lightest: #508a96;
   --color-primary-light: #416b74;
   --color-primary-dark: #274c53;
   --color-primary-darkest: #132f35;
   --color-primary: #005163;



   --color-secondary-light: #A3D2EA;
   --color-secondary: #64C0E4;
   --color-secondary-dark: #5fbde1;
   
   --color-tertiary: #FF7BAC;

   --color-text-primary: #005163;
   --color-text-secondary: #fff;
   --color-text-tertiary: #bdbdbd;

   /* --color-background: #FCFCFC;
   --color-background: #F4F4F4;
   --color-background: #FCFCF4;
   --color-background: #FCF4F4;
   --color-background: #F4F4EC; */
   --color-background: #F4FCF4;

   --color-skeleton-before: #e7e7e7;
   --color-skeleton-after: #d6d6d6;


   --color-white: #FFF;
   --color-black: #212121;
   --color-black-transparent: rgba(0, 0, 0, 0.5);

    --color-red: #DC3D3D;
    --color-red--light: #d96e6e;
    --color-yellow: #FFCB47;
    --mantine-color-error: #c55c5c;

    --gradient-gold: linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C);
}

body {
   background-color: #FCFCFC !important;
   background-image: url('./assets/fondo-web-forvet.svg');
   /*To further optimize, convert & use image in base64*/
}

/*.light-theme {*/
/*    !*COLORS*!*/
/*    --color-primary-lightest: #477283;*/
/*    --color-primary-light: #3D6271;*/
/*    --color-primary: #355562;*/
/*    --color-primary-dark: #2c4b58;*/
/*    --color-primary-darkest: #243D48;*/

/*    --color-secondary-light: #A3D2EA;*/
/*    --color-secondary: #77BDE0;*/
/*    --color-secondary-dark: #43A4D4;*/

/*    --color-tertiary: #DF3576;*/

/*    --color-text-primary: #FFF;*/
/*    --color-text-secondary: #000;*/

/*    --color-white: #FFF;*/
/*    --color-black: #333;*/
/*    --color-black-transparent: rgba(0,0,0,0.5);*/

/*    --color-red: #DC3D3D;*/
/*    --color-red--light: #d96e6e;*/
/*    --color-yellow: #FFCB47;*/
/*}*/

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

a {
   text-decoration: none;
}

hr {
   width: 100%;
   border: 1px solid var(--color-primary-light);
   margin: 16px 0;
}

body {
   background-color: var(--color-background);
}

img{
   filter: grayscale(100%) !important;
}

.mantine-Skeleton-root::before{
   background-color: var(--color-skeleton-before) !important;
}

.mantine-Skeleton-root::after{
   background-color: var(--color-skeleton-after) !important;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
   :root {
      --px: 40px;
      /*BORDER RADIUS*/
      --border-radius-lg: 12px;
      --border-radius-md: 1rem;
      --border-radius-sm: 0.8rem;
   }
}

@media screen and (max-width: 768px) {
   :root {
      --headerHeight: 100px;
      --px: 10%;
      --py: 32px;
      --layout-padding-x: 24px;
      --layout-padding-y: 16px;

      /*BORDER RADIUS*/
      --border-radius-lg: 12px;
      --border-radius-md: 1rem;
      --border-radius-sm: 0.8rem;

      /*FONT-SIZES HEADING*/
      --fs-h1: 48px;
      --fs-h2: 30px;
      --fs-h3: 23px;
      --fs-h4: 20px;
      --fs-h5: 18px;
      --fs-h6: 16px;
      /*FONT-SIZES BODY*/
      --fs-body: 16px;
      --fs-small: 14px;
      --fs-tiny: 12px;
   }
}