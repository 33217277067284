.specialtiesCarousel-father-div {
    margin-top: 24px;
}

.specialtiesCarousel-father-button{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 48px;
}

.specialtiesCarousel-link-button{
    text-decoration: none;
}