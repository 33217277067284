.EliteIntro-Card-container {
   position: sticky;
   top: 0;
   display: flex;
   width: 100%;
   transform-origin: top;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 0 4rem;
}

.EliteIntro-Card--title {
   margin-bottom: 2rem;
   text-align: center;
   font-size: var(--fs-h1);
   font-weight: 500;
}

.EliteIntro-Card--description {
   text-align: center;
   font-weight: 500;
   font-size: var(--fs-h3);
}

.EliteIntro-Card--icon {
   flex-shrink: 0;
}

@media (max-width: 768px) {
   .EliteIntro-Card-container {
      padding: 0 var(--layout-padding-x);
   }
   .EliteIntro-Card--title{
      margin-bottom: 1rem;
      font-size: var(--fs-h2);
   }
   .EliteIntro-Card--description{
      font-size: var(--fs-body);
   }
}