.footerLegend-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 56px;
}

.footerLegend-legend{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    font-size: var(--fs-h2);
    font-weight: 400;
    opacity: 0.85;
}

.footerLegend-secondLegend{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    font-size: var(--fs-h3);
    font-weight: 100;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .footerLegend-div{
        flex-wrap: wrap;
        gap: 30px;
        align-items: center;
        justify-content: center;
      }
    
    .footerLegend-legend{
        width: 90%;
        text-align: center;
    }

    .footerLegend-secondLegend{
        width: 100%;
        text-align: center;
    }

 }
 
 @media (max-width: 768px) {

    .footerLegend-div{
        flex-wrap: wrap;
        border: none;
        gap: 30px;
        align-items: center;
        justify-content: center;
      }

    .footerLegend-legend{
        width: 70%;
        max-width: var(--maxWidthContainer);
        text-align: center;
    }

    .footerLegend-secondLegend{
        width: 100%;
        text-align: center;
    }
 
 }