.specialties-cards-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 300px;
    gap: 30px;
}

.specialties-cards-heading {
    width: 100%;
    align-items: center;
    justify-content: flex-start;
}

.specialties-cards-heading h2 {
    font-size: var(--fs-h4);
    color: var(--color-text-primary);
    font-weight: 700;
    text-transform: uppercase;
}

.cardsToMap-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-template-rows: 1fr;
    gap: 10px;
    place-items: flex-start;
}

.cardToMap-withoutResults-text {
    font-size: var(--fs-body);
}

.card-skeleton-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 10px;
}

@media (max-width: 768px) {
    .specialties-cards-heading h2 {
        text-align: center;
    }

    .cardsToMap-container {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        place-items: center;
        gap: 50px;
    }

    .cardToMap-withoutResults-text {
        text-align: center;
    }
}