.registry-column-div{
    display: flex;
    flex-direction: column;
}

.registry-divContainer{
    display: flex;
    width: 100%;
}

.registry-content-div{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -80px 0 0 24px;
}

.registry-content-title{
    font-size: var(--fs-h2);
    text-align: center;
    background-color: var(--color-primary-darkest);
    padding: 40px;
    border-radius: var(--border-radius-lg);
    box-shadow: var(--box-shadow-strong);
    max-width: 90%;
}

.registry-image-div{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -80px 0 0 -24px;
}

.registry-image{
    width: auto;
    height: 70%;
    max-width: 100%;
    border-radius: var(--border-radius-lg);
}

.registry-button-div{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -80px 0 40px 0;
}


@media screen and (min-width: 768px) and (max-width:1200px){

    .registry-content-div{
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 0 24px;
    }

    .registry-button-div{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 40px 0;
    }

    .registry-button-div{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: -24px 0 12px 0;
    }

}


@media screen and (max-width: 768px) {
    .registry-content-div{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 0 0;
    }

    .registry-button-div{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: -24px 0 8px 0;
    }

}
