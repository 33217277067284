.discountsPanel__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 90%;
}

.discountsPanel__button {
    margin-bottom: 24px;
    align-self: flex-end;
}

.discountsPanel__table {
    border-collapse: collapse;
    overflow: scroll;
}

.discountsPanel__head th {
    padding: 8px 16px;
    border: 1px solid var(--color-text-primary);
    font-weight: 700;
    background-color: var(--color-primary-light);
}

.discountsPanel__table td {
    border: 1px solid var(--color-text-primary);
    padding: 8px 16px;
}

.discountsPanel__sort {
    display: flex;
    justify-content: center;
    width: 100%;
}
.discountsPanel__sort__icon {
    cursor: pointer;
    width: 24px;
}

.discountsPanel__pagination__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 8px;
    align-items: center;
    gap: 2px;
    font-size: 0.75rem;
}

.discountsPanel__paginationItems__container {
    margin-right: auto;
    margin-bottom: 2px;
}

.discountsPanel__paginationItems__label {
    margin-right: 4px;
}

.discountsPanel__paginationItems__select {
    border: 1px solid var(--color-text-primary);
    padding: 4px;
    border-radius: 4px;
    width: 48px;
}

.discountsPanel__paginationButtons__container {
    display: flex;
    gap: 4px;
}

.discountsPanel__paginationButtons__button {
    background-color: var(--color-primary-light);
    border-radius: 4px;
    border: none;
    padding: 4px;
    cursor: pointer;
}

.discountsPanel__paginationButtons__button:hover {
    background-color: var(--color-primary-light);
}

.discountsPanel__paginationButtons__button--disabled {
    background-color: var(--color-primary-darkest);
    cursor: not-allowed;
}

.discountsPanel__pagination__text {
    display: flex;
    align-items: center;
    gap: 4px;
}

.discountsPanel__pagination__input {
    border: 1px solid var(--color-text-primary);
    padding: 4px;
    border-radius: 4px;
    width: 24px;
}

/* Chrome, Safari, Edge, Opera */
.discountsPanel__pagination__input::-webkit-outer-spin-button,
.discountsPanel__pagination__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
.discountsPanel__pagination__input[type=number] {
    -moz-appearance: textfield;
}