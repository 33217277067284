.pulseFabButton-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: auto;
    height: auto;
    bottom: 40px;
    left: 40px;
    border-radius: 50px;
    z-index: 100;
}

.pulseFabButton-button {
    padding: 8px 16px;
    font-size: var(--fs-body);
    color: var(--color-text-secondary);
    background: var(--color-tertiary);
    border: none;
    border-radius: var(--border-radius-lg);
    animation: pulse 1.5s infinite;
}


.pulseFabButton-button:hover {
    cursor: pointer;
}



@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 123, 172, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}