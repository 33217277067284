.div-container-newscarousel {
    width: 100%;
    
    position: relative; 
}

/*Specific media query for NewsCarousel & NewsCard*/
@media screen and (max-width: 500px) {
    .div-container-newscarousel {
        width: 100%;
        padding: 8px;
        position: relative;
    }
}
