.courseScheduleAccordion-father-div {
  width: 100%;
  gap: 20px;
  margin-top: 8px;
}

.accordion-control-classname {
  box-shadow: var(--box-shadow);
  background-color: var(--color-primary-lightest) !important;
  border-radius: var(--border-radius-lg);
}

.mantine-Accordion-item[data-active="true"] .accordion-control-classname{
   border-bottom-left-radius: 0;
   border-bottom-right-radius: 0;
   border-top-left-radius: 12px /* var(--border-radius-lg) no lo toma */;
   border-top-right-radius: 12px /* var(--border-radius-lg) no lo toma */;
   outline: 1px solid var(--color-secondary)
}

.accordion-itemTitle-classname {
  font-size: var(--fs-h4);
  font-weight: 500;
}

.accordion-content-classname {
  font-size: var(--fs-small);
  margin-top: 8px;
}

.iconsSchool-accordion-component {
  color: var(--color-secondary);
  stroke-width: 1px;
}
