.contactCard-wrapper {
    display: flex;
    background-color: var(--color-primary-lightest );
    border-radius: var(--border-radius-lg);
    padding: 4px;
    box-shadow: var(--box-shadow);
    width: 70%;
}

.contactCard-contacts {
    border-radius: var(--border-radius-lg);
    padding: 16px; /*Same as .contactCard-form*/
    flex: 0 0 280px;
    background: linear-gradient(135deg, var(--color-primary-lightest), var(--color-primary-darkest));
}

.contactCard-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 16px; /*Same as .contactCard-contacts*/
    margin-left: 32px;
    color: var(--color-text-secondary);
}

.contactCard-fields {
    width: 100%;
    padding: 0 32px;
}

.contactCard-items {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 24px 0;
}

.contactCard-title {
    font-family: var(--font-primary);
    font-size: var(--fs-h3);
    line-height: var(--fs-h3);
    text-align: center;
}

.contactCard-title--left {
    margin-bottom: 16px;
    color: var(--color-text-secondary);
}
.contactCard-title--right {
    margin-bottom: 32px;
    color: var(--color-text-secondary);
}


@media screen and (min-width: 769px) and (max-width: 1200px) {
    .contactCard-wrapper {
        border-radius: var(--border-radius-lg);
        box-shadow: var(--box-shadow);
        padding: 4px;
        width: 100%;
    }

    .contactCard-contacts {
        border-radius: var(--border-radius-lg);
        padding: 16px; /*Same as .contactCard-form*/
        flex: 0 0 200px;
        max-width: 40%;
    }

    .contactCard-form {
        padding: 16px; /*Same as .contactCard-contacts*/
        margin-left: 0;
    }

    .contactCard-fields {
        width: 100%;
        padding: 0 24px;
    }

    .contactCard-items {
        margin: 24px 0;
    }

    .contactCard-title--left {
        margin-bottom: 16px;
    }
    .contactCard-title--right {
        margin-bottom: 32px;
    }
}

@media screen and (max-width: 768px) {
    .contactCard-wrapper {
        flex-direction: column;
        border-radius: var(--border-radius-lg);
        box-shadow: var(--box-shadow);
        width: 100%;
    }

    .contactCard-contacts {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-bottom: 8px;
        border-radius: var(--border-radius-lg);
        padding: 16px; /*Same as .contactCard-form*/
        /*flex: 0 0 200px;*/
    }

    .contactCard-form {
        padding: 16px; /*Same as .contactCard-contacts*/
        margin-left: 0;
    }

    .contactCard-fields {
        width: 100%;
        padding: 0 8px;
    }

    .contactCard-items {
        margin: 8px 0;
    }

    .contactCard-title--left {
        margin-bottom: 0;
    }
    .contactCard-title--right {
        margin-bottom: 16px;
    }
}
