.course-certification-container {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 20px;
}

.course-certification-heading {
   display: flex;
   flex-direction: column;
   gap: 15px;
}

.course-certification-heading-description {
   font-size: var(--fs-body);
   color: var(--color-text-primary);
}

.certification-image-container {
   display: flex;
   width: 100%;
   max-width: 500px;
}

.certification-image {
   width: 100%;
   height: auto;
   object-fit: cover;
}

@media (max-width: 768px) {
   .course-certification-container{
      padding-right: 0;
   }
   .certification-image-container {
      max-width: unset;
   }
}