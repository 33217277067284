.EliteOffer-disappearing-features-container {
   position: relative;
   height: fit-content;
   scroll-behavior: smooth;
   width: 100%;
}

.EliteOffer-features-grid {
   position: relative;
   display: grid;
   grid-template-columns: 1fr;
   gap: 2rem;
   max-width: 112rem;
   width: 100%;
   padding: var(--mt);
   margin: 0 auto;
}

.EliteOffer-copy-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   height: fit-content;
   padding: 3rem 0;
   width: 100%;
   position: relative;
}

.EliteOffer-copy-title {
   margin-top: 0.5rem;
   margin-bottom: 1rem;
   font-size: var(--fs-h2);
   font-weight: 500;
   line-height: 1.2;
   background:linear-gradient(to right, #BF953F, #FCF6BA,#FBF5B7, #AA771C);
  background-size: cover;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.EliteOffer-copy-text {
   font-size: var(--fs-body);
   color: var(--color-text-secondary);
   opacity: .7;
   background: linear-gradient(45deg, #FFD700, #353100);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
}

.EliteOffer-carousel-container {
   position: relative;
   width: 100%;
   overflow-x: hidden;

}

.EliteOffer-carousel-content {
   position: relative;
   display: flex;
   flex-direction: column;
   gap: 1.5rem;
}

.EliteOffer-carousel-gradient {
   position: sticky;
   top: 0;
   height: 6rem;
   width: 100%;
   background: linear-gradient(to bottom, #1d1d1d, rgba(238, 242, 255, 0));
   z-index: 10;
   display: none;
}

.EliteOffer-carousel-buffer {
   height: 6rem;
   width: 100%;
}

@media (min-width: 768px) {
   .EliteOffer-features-grid {
      grid-template-columns: 20% 1fr;
   }

   .EliteOffer-copy-container {
      position: sticky;
      top: 0;
      height: 100vh;
   }

   .EliteOffer-carousel-buffer {
      height: 12rem;
   }

   .EliteOffer-carousel-content {
      gap: 3rem;
   }

   .EliteOffer-carousel-gradient {
      display: block;
   }
}