.paymentPage-father-div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.paymentPage-inscription-confirmed-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.paymentPage-image-container-div {
  padding-bottom: 24px;
}

.paymentPage-image-styling {
  height: 100px;
  width: auto;
  margin-bottom: 24px;
}

.paymentPage-inscription-details-div {
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
}

.paymentPage-confirmation-legend {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
  font-size: var(--fs-h3);
  color: var(--color-text-primary);
}

.paymentPage-description-legend {
  font-size: var(--fs-body);
  color: var(--color-text-primary);
}

.paymentPage-operation-div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.paymentPage-operation-code{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
  font-size: var(--fs-body);
  color: var(--color-text-primary);
}

.paymentPage-operation-code-span{
  padding-left: 8px;
  font-weight: 700;
  font-size: var(--fs-h3);
  color: var(--color-text-primary);
}

.paymentPage-questions{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
  font-size: var(--fs-body);
  text-align: center;
  color: var(--color-text-primary);
}

/* .paymentPage-details-div {
  display: flex;
  flex-direction: column;
}
.paymentPage-details-header {
  padding-bottom: 8px;
  font-size: var(--fs-h4);
}
.paymentPage-course-description-div {
  display: flex;
  flex-direction: column;
}

.paymentPage-course-details-div {
  display: flex;
}

.paymentPage-course-header {
  font-size: var(--fs-h5);
}

.paymentPage-course-detail {
  font-size: var(--fs-small);
  margin-left: auto;
}

.paymentPage-dotted-line-div {
  border-top: 2px dashed var(--color-primary);
  margin: 16px 0;
}

.paymentPage-resume-header {
  font-size: var(--fs-small);
}

.paymentPage-course-cost-div {
  display: flex;
}

.paymentPage-course-div-header {
  font-size: var(--fs-h4);
}

.paymentPage-course-price-div {
  font-size: var(--fs-h4);
  margin-left: auto;
} */

.paymentPage-button-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.paymentPage-firstButton-root{
  margin: 16px 0;
}

@media screen and (max-width: 768px) {
  .paymentPage-course-details-div {
    display: flex;
    flex-direction: column;
  }

  .paymentPage-confirmation-legend {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 16px;
    font-size: var(--fs-h3);
  }

  .paymentPage-description-legend {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: var(--fs-small);
  }

  .paymentPage-operation-code{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 16px;
    font-size: var(--fs-body);
  }
  

  .paymentPage-button-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .paymentPage-firstButton-root{
    margin: 16px 0;
  }
}
