.courseCardModal-container {
  width: 100%;
}

.courseCardModal-firstGrid-element-card {
  width: 100%;
  height: 120px;
  cursor: pointer;
}

.courseCardModal-firstGrid-element-content {
  width: 100%;
  height: 100%;
  box-shadow: var(--box-shadow);
  transition: transform 1s;
  transform-style: preserve-3d;
}

.courseCardModal-firstGrid-element-card.clicked .courseCardModal-firstGrid-element-content {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}

.courseCardModal-firstGrid-element-front,
.courseCardModal-firstGrid-element-back {
  position: absolute;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 16px;
  text-align: center;
  font-size: var(--fs-small);
  border-radius: var(--border-radius-lg);
  backface-visibility: hidden;
  background-color: #477283 !important; /*--color-primary-lightest*/
  box-shadow: var(--box-shadow);
}

.courseCardModal-firstGrid-element-front {
  align-items: center;
  justify-content: center;
  height: 100%;
}


.courseCardModal-firstGrid-image {
  width: auto;
  height: 90%;
  border-radius: var(--border-radius-lg);
}

.courseCardModal-firstGrid-element-back {
  transform: rotateY(180deg);
  align-items: flex-start;
  padding: 16px;
  height: auto;
}

.courseCardModal-root-modal {
  border-radius: var(--border-radius-lg) !important;
}

.courseCardModal-title-modal {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: var(--fs-h3) !important;
  margin: 8px 16px !important;
}

.courseCardModal-header-modal {
  padding: 32px 0 !important;
}

.courseCardModal-back-content{
  display: flex;
  flex-direction: column;
}

.courseCardModal-text-content{
  margin-bottom: 8px;
}