.specialties-hero-container {
   width: 100%;
   display: flex;
   justify-content: center;
   gap: 20px;
   min-height: 300px;
   z-index: 1;
}

.specialties-hero-heading {
   min-height: 100%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: center;
   flex-grow: 1;
}

.specialties-hero-heading h2 {
   font-size: var(--fs-h2);
   color: var(--color-text-primary);
   text-transform: uppercase;
}

.specialties-hero-heading p {
   font-size: var(--fs-body);
   max-width: 70ch;
   color: var(--color-text-primary);
}

.specialties-hero-imageOver-container {
   min-height: 100%;
   display: flex;
   align-items: flex-end;
   justify-content: flex-end;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
   .specialties-hero-heading p {
      max-width: 100%;
   }
   .specialties-hero-imageOver-container{
      justify-content: center;
      max-width: 40%;
   }
   .specialties-hero-imageOver-container .imageOverShape-container{
      width: 100%;
   }
}

@media (max-width: 768px) {
   .specialties-hero-container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 100px;
   }
   .specialties-hero-heading{
      order: 1;
      align-items: center;
   }

   .specialties-hero-heading p {
      text-align: center;
   }

   .specialties-hero-imageOver-container{
      justify-content: center;
      width: 100%;
      order: 2;
   }

   .specialties-hero-imageOver-container .imageOverShape-container{
      width: 100%;
   }
}