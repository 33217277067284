.coursePaymentPage-section {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: var(--layout-padding-y) var(--layout-padding-x);
}

.coursePaymentPage-layout {
  display: grid;
  grid-template-columns: 7fr 5fr;
  grid-template-areas: "main aside";
  grid-gap: 24px;
  width: 100%;
  height: 100%;
}

.coursePaymentPage-aside {
  grid-area: aside;
  width: 100%;
  height: 100%;
  padding-right: var(--layout-padding-x);
  z-index: 1;
}

.coursePaymentPage-main {
  grid-area: main;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .coursePaymentPage-layout {
    grid-template-columns: 6fr 6fr;
  }
}

@media screen and (max-width: 768px) {
  .coursePaymentPage-main {
    padding: 0 var(--layout-padding-x);
  }
  .coursePaymentPage-layout {
    grid-template-columns: 1fr;
    grid-template-areas:
      "main"
      "aside";
    grid-gap: 24px;
    width: 80%;
    height: 100%;
    padding: 0;
  }

  .coursePaymentPage-aside {
    margin-top: 0;
    padding: 0 var(--layout-padding-x) var(--layout-padding-y);
  }
}
