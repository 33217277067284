.BlogPage-main-container {
   min-height: 100vh;
   display: flex;
   width: 100%;
   flex-direction: column;
}

.BlogPage--pageTitle {
   padding: 0 var(--layout-padding-x);
   font-size: var(--fs-h1);
   color: var(--color-text-primary);
   text-align: left;
   width: 100%;
}

.BlogPage-wrapper-content {
   display: flex;
   width: 100%;
   flex-direction: column;
   gap: 54px;
}

.BlogPage-lastPost-container {
   padding: var(--layout-padding-y) var(--layout-padding-x);
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-template-areas:
      "blog-1 blog-2"
      "blog-1 blog-3"
      "blog-1 blog-4";
   column-gap: 24px;
}

.BlogPage-generalPost-container {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
   gap: 40px;
   width: 100%;
   /* background-color: var(--color-primary-light); */
   padding: var(--layout-padding-x) var(--layout-padding-x);
}

@media (max-width: 768px) {
   .BlogPage-lastPost-container {
      padding: var(--layout-padding-y) var(--layout-padding-x);
      display: flex;
      flex-direction: column;
      gap: 24px;
   }
}

@media (max-width: 370px) {
   .BlogPage-generalPost-container{
      grid-template-columns: 1fr;
   }
}