.footer {
   position: relative;
   width: 100%;
   padding-bottom: 70px;
   /* background-color: var(--color-black); */
   background-color: var(--color-primary-darkest);
   color: var(--color-text-secondary);
   max-width: var(--maxWidthContainer);

   z-index: 99;
   overflow-x: hidden;
}

.footer-bkgImage{
   height: 250px;
   width: auto;
   object-fit: cover;
   position: absolute;
   bottom: 0;
   right: 0;
   filter: drop-shadow(-10px -4px 4px #00000065) brightness(50%);
}

@media (max-width: 768px) {
   .footer-bkgImage{
      width: 100%;
      object-fit: contain;
      z-index: -1;
      margin-right: -24px;
   }
}