.icon-card-text-container{
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: flex-start;
   gap: 10px;
   border: 1px solid var(--color-secondary);
   border-radius: var(--border-radius-lg);
   padding: 16px;
}

.icon-card-text-title{
   font-size: var(--fs-body);
   color: var(--color-text-primary);
   font-weight: 700;
}

.icon-card-text-paragraph{
   font-size: var(--fs-small);
   color: var(--color-text-primary);
}