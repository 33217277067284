.bank-account-container{
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: center;
   width: 100%;
   padding: 10px 20px;
   border-radius: var(--border-radius-lg);
   background-color: var(--color-primary-lightest);
   box-shadow: var(--box-shadow);
   gap: 10px;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
   .bank-account-container{
      padding: 10px 60px;
   }
}

@media screen and (max-width: 350px) {
   .bank-account-container{
      gap: 20px;
   }
}