.contactBanner-father-div {
  display: flex;
  width: 100%;
  background-color: var(--color-primary-dark);
  border-radius: var(--border-radius-md);
  position: relative;
  height: 432px;
  overflow: visible;
}

.contactBanner-father-div:hover img{
   filter: grayscale(0) !important;
}

.contactBanner-info-container {
  display: flex;
  flex-direction: column;
  padding: 72px;
  width: 60%;
}

.contactBanner-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
}

.contactBanner-title-container .sectionTitle-downText{
   color: var(--color-text-secondary);
}

.contactBanner-button-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.contactBanner-image-div-container {
  width: 40%;
  position: relative;
}

.contactBanner-image-div-styling {
  position: absolute;
  bottom: -16px;
  left: 8px;
  height: 488px;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .contactBanner-info-container {
    display: flex;
    flex-direction: column;
    padding: 72px;
    width: 75%;
  }

  .contactBanner-image-div-container {
    width: 25%;
    position: relative;
  }

  .contactBanner-image-div-styling {
    position: absolute;
    bottom: -16px;
    left: -176px;
    height: 488px;
  }
}

@media screen and (max-width: 768px) {
  .contactBanner-father-div {
    display: flex;
    width: 100%;
    position: relative;
    height: 432px;
    overflow: hidden;
    z-index: 1;
  }

  .contactBanner-info-container {
    display: flex;
    flex-direction: column;
    padding: 72px;
    width: 100%;
  }

  .contactBanner-image-div-container {
    width: 40%;
    position: absolute;
    z-index: -1;
    opacity: 0.5;
  }

  .contactBanner-image-div-styling {
    position: relative;
    bottom: -8px;
    left: 0;
    height: 432px;
  }

  .contactBanner-button-div {
    width: 100%;
  }
}
