.courseReviewsCard-container {
  max-height: 584px;
  overflow-y: auto;
}

.courseReviewscard-card-content-container {
  background-color: var(--color-primary-lightest);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--box-shadow);
  margin-top: 32px;
  margin-bottom: 16px;
  max-height: 100%;
  overflow-y: auto;
  width: 97%;
}

.courseReviewsCard-title-container-div {
  margin: 24px 0;
}

.courseReviewscard-content-container-div {
  padding: 16px;
}

.courseReviewscard-avatar-and-text-container-div {
  display: flex;
}

.courseReviewscard-personal-info-div-container {
  padding-left: 32px;
}

.courseReviewsCard-typography-p-custom {
  padding-left: 88px;
  font-size: var(--fs-small);
}

.courseReviewsCard-no-reviews {
  font-size: var(--fs-small);
  text-align: center;
  margin: 24px 0;
  color: var(--color-text-primary);
}

.courseReviewscard-personal-info-h5-title {
  display: flex;
  font-size: var(--fs-h5);
}

.courseReviewscard-date-p {
  font-size: var(--fs-tiny);
  color: var(--color-text-primary);
  opacity: 0.5;
}
