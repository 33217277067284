.coursePaymentForm-father-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 1;
}

.coursePaymentForm-father-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 80%;
    /* background-color: var(--color-primary-lightest); */
    background-color: transparent;
    box-shadow: var(--box-shadow-md);
    padding: 32px 48px;
    border-radius: var(--border-radius-lg);
    color: var(--color-text-primary);
    
}

.coursePaymentForm-description-text {
    width: 100%;
    text-align: center;
    margin: 8px;
    font-weight: 400;
    font-size: var(--fs-body);
}

.coursePaymentForm-already-have-account {
    width: auto;
    align-self: center;
    font-size: var(--fs-tiny);
    line-height: calc(var(--fs-tiny) * 1.2);
    text-align: center;
    margin: 8px 0;
    /*text-decoration: underline;*/
    cursor: pointer;
    background-color: var(--color-tertiary);
    color: var(--color-text-secondary);
    padding: 4px 8px;
    border-radius: var(--border-radius-lg);
}
.coursePaymentForm-already-have-account:hover {
   opacity: .8;
}

.coursePaymentForm-description-firstStep {
    font-size: var(--fs-body);
    margin: 8px 0 0 0;
}

.coursePaymentForm-form-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.coursePaymentForm-first-textInput {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    width: 100%;
}

.coursePaymentForm-second-textInput {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 0 8px 0;
}

.coursePaymentForm-form-styling {
    width: 100%;
}

.coursePaymentForm-textInput-wrapper {
    width: 100%;
}

.coursePaymentForm-input-wrapper{
   background-color: transparent !important;
   color: var(--color-text-primary) !important;
}

.coursePaymentForm-textInput-error {
    word-wrap: break-word;
    font-size: var(--fs-tiny) !important;
}

.coursePaymentForm-form-styling .PhoneInputCountrySelect{
   color: var(--color-text-primary);
   background-color: transparent;
}

.coursePaymentForm-form-styling .PhoneInputInput > div > input.mantine-Input-input {
   background-color: transparent;
   color: var(--color-text-primary) !important;
}

.coursePaymentForm-third-textInput {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 0 8px 0 !important;
}

.coursePaymentForm-textInput-labels {
    font-size: var(--fs-small) !important;
    width: 100%;
}

.coursePaymentForm-button-div {
    display: flex;
    justify-content: center;
}

.coursePaymentForm-button-styling {
    margin: 8px 0 0 0;
}

.coursePaymentForm-note {
    font-size: var(--fs-tiny);
    line-height: calc(var(--fs-tiny) * 1.2);
    margin: 16px 0 0 0;
    color: var(--color-text-primary);
}

.CoursePaymentForm--modalTitle{
   font-size: var(--fs-h3);
   color: var(--color-text-primary);
   text-align: center;
}

.whyAskInformation-text{
   font-size: var(--fs-small);
   text-align: left;
}

.dniExamples--btnText{
   color: var(--color-text-primary);
   text-decoration: underline;
   margin-left: 4px;
}


.dniExamplesModal--title{
   font-size: var(--fs-h4);
   color: var(--color-text-primary);
   margin-bottom: 8px;
}

.dniExamplesModal--countryColor{
   border-radius: 50%; 
   height: 16px;
   width: 16px;
}

.dniExamplesModal--countryName, .dniExamplesModal--countryDni{
   font-size: var(--fs-body);
}

.dniExamplesModal--countryDni{
   opacity: .8;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .coursePaymentForm-father-container {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .coursePaymentForm-father-container {
        width: 100%;
        padding: 24px; /*Same as coursePaymentCard-father-div*/
    }
}
