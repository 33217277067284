.courseDetailsCard__container {
    background-color: var(--color-primary-lightest);
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    border-radius: var(--border-radius-md);
    box-shadow: var(--box-shadow);
    padding: 24px;

    position: sticky;
    position: -webkit-sticky;
    top: calc(var(--headerHeight) + 24px);
}

.courseDetailsCard__price {
    font-size: var(--fs-h2);
    line-height: var(--fs-h2);
    font-weight: 300;
    color: var(--color-text-secondary);
}

.courseDetailsCard__badge {
    margin: 16px 0;
    background-color: var(--color-tertiary) !important;
}

.courseDetailsCard__button__container {
    width: 100%;
}

.courseDetailsCard__button {
    margin: 8px 0;
}

.courseDetailsCard__button:hover{
   opacity: .7;
}


@media screen and (min-width: 769px) and (max-width: 1200px) {
    .courseDetailsCard__container {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .courseDetailsCard__container {
        background-color: transparent;
        box-shadow: none;
        width: 100%;
        min-width: 80%;

        align-items: center;
    }

   .courseDetailsCard__price {
      color: var(--color-text-primary);
   }
    .courseDetailsCard__button__container {
        display: none;
    }
    .courseDetailsCard__hr {
        display: none;
    }
}