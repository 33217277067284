.transfer-bank-steps-container {
   display: flex;
   width: 40%;
   padding-top: 10px;
}

.transfer-bank-follow-steps-container {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 30px;
   width: 100%;
}

.transfer-bank-follow-steps-headline {
   font-size: var(--fs-h5);
   font-weight: 400;
   width: 100%;
   color: var(--color-text-primary);
}

.transfer-bank-steps-items-container {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 30px;
}

.transfer-bank-steps-item {
   position: relative;
   display: flex;
   width: 100%;
   align-items: center;
   justify-content: flex-start;
   gap: 20px;
}


.transfer-bank-steps-text {
   font-size: var(--fs-body);
   color: var(--color-text-primary);
}

.transfer-bank-steps-item--icon {
   color: var(--color-text-primary);
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
   .transfer-bank-steps-container{
      width: 100%;
   }
}

@media screen and (max-width: 768px) {
   .transfer-bank-steps-container{
      width: 100%;
   }

   .transfer-bank-follow-steps-headline{
      text-align: center;
   }

   .transfer-bank-steps-item{
      flex-direction: column;
      align-items: center;
      justify-content: center;
   }

   .transfer-bank-steps-text{
      text-align: center;
   }
}