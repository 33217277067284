.suggestForm-container {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 30px;
}

.suggestForm-container p {
   font-size: var(--fs-body);
   color: var(--color-text-primary);
}

.suggestForm--form {
   display: flex;
   flex-direction: column;
   width: 100%;
   max-width: 770px;
   color: var(--color-text-primary);
   gap: 20px;
}

.suggestForm-notification-container {
   display: flex;
   align-items: center;
   gap: 10px;
}

@media (max-width: 768px) {
   .suggestForm--form {
      width: 80%;
   }
}