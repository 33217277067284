.whatsAppFAB-float {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  color: #fff;
  border-radius: 50px;
  box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.whatsAppFAB-my-float {
  margin-top: -2px;
  height: 40px;
}


