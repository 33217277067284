.decorativeCircle {
    border: 100px solid var(--color-primary-dark);
    border-radius: 50%;
    background-color: transparent;
    opacity: 0.5;
    position: absolute;
    z-index: 0;
}

.decorativeCircle__shadow-1 {
    box-shadow:  8px 8px 16px 4px rgba(0,0,0,0.5);
}
.decorativeCircle__shadow-2 {
    box-shadow:  -8px -8px 16px 4px rgba(0,0,0,0.5);
}
.decorativeCircle__shadow-3 {
    box-shadow:  0 0 16px 4px rgba(0,0,0,0.5);
}
.decorativeCircle__shadow-4 {
    box-shadow:  0 8px 16px 4px rgba(0,0,0,0.5);
}
.decorativeCircle__shadow-5 {
    box-shadow:  8px 0 16px 4px rgba(0,0,0,0.5);
}
.decorativeCircle__shadow-6 {
    box-shadow:  0 -8px 16px 4px rgba(0,0,0,0.5);
}