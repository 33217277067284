.account-bank-info-item-container{
   display: flex;
   align-items: center;
   width: 100%;
   font-size: var(--fs-body);
   gap: 10px;
}

.account-bank-info-item-headline{
   font-size: var(--fs-body);
   font-weight: 500;
}

.account-bank-info-item-textGroup{
   display: flex;
   flex-grow: 1;
   align-items: center;
   justify-content: flex-end;
   gap: 10px;
}

.textGroup--paddingRight{
   padding-right: 30px;
}

.account-bank-info-item-text{
   font-size: var(--fs-body);
   font-weight: 100;
}

.account-bank-info-item-text--bold{
   font-weight: 400;
}

.account-bank-info-item-copyIcon{
   width: 20px;
   height: 20px;
   cursor: pointer;
   transition: all .3s;
}

.item-copied{
   transition: all .3s;
}

@media screen and (max-width: 768px) {
   .textGroup--paddingRight{
      padding-right: 0px;
   }
   .account-bank-info-item-headline{
      font-size: var(--fs-small);
   }
   .account-bank-info-item-text{
      font-size: var(--fs-small);
   }
}

@media screen and (max-width: 350px) {
   .account-bank-info-item-container{
      flex-direction: column;
      gap: 0;
   }

   .account-bank-info-item-textGroup{
      flex-direction: column;
      gap: 0;
   }

   .account-bank-info-item-text{
      text-align: center;
   }
}