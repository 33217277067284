.LandingHero-main-wrapper .tinyDecorativeCircle,
.LandingHero-main-wrapper .decorativeCircle
{
   filter: blur(14px);
}

.landingHero__container {
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   z-index: 1;
   gap: 24px;
}

.landingHero__column {
   width: 100%;
   height: 100%;
   display: flex;
   gap: 24px;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.landingHero__title {
   font-family: var(--font-primary);
   font-size: var(--fs-h1);
   line-height: calc(var(--fs-h1)*1.2);
   color: var(--color-text-primary);
   margin-bottom: 0;
   text-align: center;
   text-transform: uppercase;

   font-family: var(--font-secondary);
   color: transparent;
   -webkit-text-stroke:2px var(--color-text-primary);
   text-stroke: 2px var(--color-text-primary); 
}


.landingHero__subtitle {
   font-size: var(--fs-small);
   /* background-color: var(--color-primary-lightest); */
   padding: 8px 12px;
   border-radius: var(--border-radius-lg);
   font-weight: 400;
   /* box-shadow: var(--box-shadow); */
   text-align: center;
   /* animation: bgColorChange 36s infinite alternate; */
}

.landingHero__text {
   font-family: var(--font-primary);
   font-size: var(--fs-body);
   line-height: calc(var(--fs-body)*1.5);
   color: var(--color-text-primary);
}

.landingHero__offer-text{
   font-size: var(--fs-small);
   color: var(--color-text-primary);
   opacity: .8;
}

.landingHero__offerLink{
   text-decoration: underline;
   font-size: var(--fs-body);
   color: var(--color-text-primary);
   font-weight: 400;
}

.landingHero__offerLink:hover{
   opacity: .8;

}



.landingHero__gradient {
   font-family: var(--font-primary);
   color: var(--color-text-primary);
   /* -webkit-text-stroke:2px var(--color-text-primary); */
   /* text-stroke: 2px var(--color-text-primary);  */
 }
 

.landingHero__block {
   width: 100%;
   text-align: center;
   height: auto;
   margin: 16px 0 32px;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {

   .landingHero__title {
      font-size: var(--fs-h2);
      line-height: calc(var(--fs-h2)*1.2);
   }

   .landingHero__text {
      font-size: var(--fs-small);
      line-height: calc(var(--fs-small)*1.5);
   }



}

@media screen and (max-width: 768px) {
   .landingHero__container {
      height: unset;
      display: flex;
      flex-direction: column;
      align-items: center;
   }
}


@keyframes bgColorChange {
   0% {
       background-color: var(--color-primary-lightest); 
   }
   33% {
       background-color: var(--color-primary);
   }
   66% {
       background-color: var(--color-secondary-dark); 
   }
   100% {
       background-color: var(--color-tertiary); 
   }
}