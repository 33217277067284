.team-container{
   width: 100%;
   display: grid;
   place-items: center;
}

.team-first-container{
   display: flex;
   flex-direction: column;
   gap: 50px;
   width: 100%;
   overflow-y: visible;
}

.team-title-bubble-group{
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 10px;
}

.team-title-bubble-group h3{
   font-size: var(--fs-h3);
   font-weight: normal;
   color: var(--color-text-primary);
}

