.mega-menu-container {
   position: absolute;
   top: calc(var(--headerHeight) - 20px);
   right: 0;
   max-height: calc(100vh - var(--headerHeight));
   border-bottom-left-radius: var(--border-radius-lg);
   border-bottom-right-radius: var(--border-radius-lg);
   opacity: 0;
   visibility: hidden;
   box-shadow: var(--box-shadow);
}

.mega-menu-container::before {
   position: absolute;
   content: '';
   z-index: 20;
   right: 10%;
   top: -16px;
   height: 16px;
   width: 16px;
   -webkit-clip-path: polygon(76% 0, 0% 100%, 100% 100%);
   clip-path: polygon(76% 0, 0% 100%, 100% 100%);
   background-color: var(--color-background);
   box-shadow: 1px 8px 10px -4px var(--color-background) inset;
}

.mega-menu-list {
   width: 768px;
   height: calc(450px + 40px);
}

.mega-menu--open {
   opacity: 1;
   visibility: visible;
   transition: all .3s;
}

@media screen and (min-width: 1051px) and (max-width: 1200px) {
   .mega-menu-container{
      right: -90%;
   }
   .mega-menu-container::before{
      right:40%;
   }
}

@media screen and (min-width: 901px) and (max-width: 1050px) {
   .mega-menu-container{
      right: -145%;
   }
   .mega-menu-container::before{
      right:50%;
   }
}

@media screen and (min-width: 769px) and (max-width: 900px) {
   .mega-menu-container{
      right: -218%;
      top: calc(var(--headerHeight) - 40px);
   }
   .mega-menu-container::before{
      right:50%;
   }
}

@media screen and (max-width: 768px) {
   .mega-menu-container {
      position: static;
      display: none;
      top: unset;
      right: unset;
      max-height:max-content;
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
      box-shadow: unset;
      padding-left: 10px;
   }
   .mega-menu--open {
      display: flex;
      opacity: 1;
      visibility: visible;
      transition: all .3s;
   }

   .mega-menu-container::before {
      display: none;
   }

   .mega-menu-list {
      height: max-content;
   }
}