.testimonialElement-father-div {
    position: relative;
    overflow: hidden;
    margin: 8px;
    width: 98%;
    color: #333;
    text-align: left;
  }
  .testimonialElement-father-div * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .testimonialElement-father-div blockquote {
    display: block;
    border-radius: 8px;
    position: relative;
    background-color: var(--color-primary-light);
    padding: 32px 48px 64px 48px;
    font-size: var(--fs-small);
    margin: 0 0 -48px;
    line-height: 1.6em;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.15);
    color: var(--color-text-secondary);
  }
  .testimonialElement-father-div blockquote:before{
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.15) ;
  }
  .testimonialElement-father-div blockquote:after {
    position: absolute;
    opacity: 0.3;
  }
  .testimonialElement-father-div blockquote:before {
    top: 36px;
    left: 24px;
  }
  .testimonialElement-father-div blockquote:after {
    right: 24px;
    bottom: 36px;
  }
  .testimonialElement-father-div .author {
    text-transform: uppercase;
    text-align: center;
    color:black;
  }
  .testimonialElement-father-div .author h5 {
    margin: 0;
    font-size: var(--fs-h5);
    color: var(--color-text-primary);
  }

.testimonialElement-avatar-container{
    display: flex;
    align-items: center;
    justify-content: center;
}