.aboutUsPage-section {
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: var(--layout-padding-y) var(--layout-padding-x);
}

/* .aboutUsPage-section:first-child{
   padding-top: var(--headerHeight);
} */

.aboutUsPage-section--noPadding {
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}

/*---    cambiando componente < ImageOverShape />    ---*/
.aboutUsPage-vision-mission-section .specialties-hero-container {
   padding: var(--layout-padding-y) 0;
   align-items: center;
}

/* cambiando seccion VISION */
.aboutUsPage-vision-mission-section:nth-child(4) .specialties-hero-container {
   gap: 10%;
}

.aboutUsPage-vision-mission-section .specialties-hero-container .specialties-hero-imageOver-container .imageOverShape-container {
   width: 100%;
}

.aboutUsPage-vision-mission-section .specialties-hero-container .specialties-hero-imageOver-container .imageOverShape-container .imageOverShape-image {
   height: 300px !important;
}

.aboutUsPage-vision-mission-section .specialties-hero-container .specialties-hero-heading h2 {
   color: var(--color-text-primary);
   font-weight: 600;
}

.aboutUsPage-vision-mission-section .specialties-hero-container .specialties-hero-heading p {
   color: var(--color-text-primary);
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
   .aboutUsPage-hero-container .specialties-hero-imageOver-container {
      max-width: 100%;
  }
   /* cambiando seccion VISION */
   .aboutUsPage-vision-mission-section:nth-child(4) .specialties-hero-container{
      gap: 20px;
   }

   .aboutUsPage-vision-mission-section .specialties-hero-container .specialties-hero-imageOver-container{
      width: 45%;
   }
}

@media screen and (max-width: 768px) {
   .aboutUsPage-vision-mission-section .specialties-hero-container .specialties-hero-imageOver-container {
      width: 100%;
   }

   /* cambiando seccion VISION */
   .aboutUsPage-vision-mission-section:nth-child(4) .specialties-hero-container {
      gap: 100px;
   }
   
   .aboutUsPage-vision-mission-section .specialties-hero-container .specialties-hero-heading p {
      text-align: center;
   }
}