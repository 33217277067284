.contactCardIcon-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    margin: 8px 0;
}

.contactCardIcon-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    color: var(--color-text-secondary);
}

.contactCardIcon-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.contactCardIcon-title {
    margin-bottom: 4px;
    font-family: var(--font-primary);
    font-weight: 300;
    font-size: var(--fs-h6);
    color: var(--color-text-secondary);
}

.contactCardIcon-text {
    font-family: var(--font-primary);
    font-weight: 500;
    font-size: var(--fs-body);
    color: var(--color-text-secondary);
}

.contactCardIcon-text:hover {
    color: var(--color-tertiary);
}

.contactCardIcon-info{
    font-family: var(--font-primary);
    font-weight: 300;
    font-size: var(--fs-tiny);
    color: var(--color-text-secondary);
}



@media screen and (min-width: 769px) and (max-width: 1200px) {
    .contactCardIcon-content {
        max-width: 85%;
        overflow-x: hidden;
    }
}

@media screen and (max-width: 768px) {
    .contactCardIcon-wrapper {
        margin: 8px auto;
    }

    .contactCardIcon-content {
        max-width: 85%;
        overflow-x: hidden;
    }
}
