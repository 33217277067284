.eliteButton {
    color: var(--color-text-secondary); /* Color del texto */
    background: linear-gradient(to right, #BF953F, #c29a4b, #B38728, #c29a4b, #AA771C);
    /* background-color: #B38728; */

    position: relative;
    overflow: hidden;
    cursor: pointer;

    display: inline-block;
    width: auto;
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    font-size: var(--fs-tiny);
    font-weight: 500;
    border-radius: var(--border-radius-lg);

    text-transform: uppercase;
    text-decoration: none;
    transition: color 0.3s ease;
    text-align: center;


    &:before {
        content: '';
        position: absolute;
        top: -50px;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,0.15);
        border-right: 50px solid transparent;
        transform: translateX(-100%);
        transition: transform 0.5s ease;
    }

    &:hover {

        &:before {
            transform: translateX(0);
        }
    }
}
