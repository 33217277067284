.avalContainer{
   display: flex;
   justify-content: center;
   height: 100%;
   width: 100%;
   background-color: var(--color-primary-dark);
   background-image: url('../../../../assets/fondo-web-forvet.svg');
   background-position:center;
   background-size: cover;
   background-repeat: no-repeat;
}

.avalSection-container{
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   gap: 40px;
   width: 100%;
   max-width: var(--maxWidthContainer);
   padding: var(--py) var(--px);
   
}

.avalSection-title{
   text-align: center;
   font-size: var(--fs-h2);
   color: var(--color-text-secondary);
}

.avalSection-container .skeleton-container{
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 20px;
}

@media (max-width: 768px) {
   .avalSection-container{
      gap: 20px;
   }
}