.aboutUs-section {
   width: 100%;
   display: grid;
   place-items: center;
}

.aboutUs-first-container {
   width: 100%;
   max-width: var(--maxWidthContainer);
   /* padding: var(--py) var(--px); */
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 20px;
}

.aboutUs-pictures-container {
   width: auto;
   display: flex;
   justify-content: center;
   align-items: center;
}

.aboutUs-image-container {
   display: flex;
   justify-content: center;
   gap: 15px;
   /*min-height: 370px;*/
}

.aboutUs-image:first-child {
   height: 230px;
   width: 230px;
   object-fit: cover;
   align-self: flex-end;

   overflow: hidden;
   border-radius: 50%;
   border: 16px solid transparent;
   background: linear-gradient(45deg,var(--color-primary),var(--color-secondary)) border-box;
}

.aboutUs-image:last-child {
   height: 320px;
   width: 320px;
   object-fit: cover;
   align-self: flex-start;
   overflow: hidden;
   border-radius: 50%;
   border: 16px solid transparent;
   background: linear-gradient(135deg,var(--color-primary),var(--color-secondary)) border-box;
}

.aboutUs-text-container {
   max-width: 50%;
   display: flex;
   flex-direction: column;
   flex-grow: 1;
   gap: 30px;
}

.aboutUs-paragraph {
   font-size: var(--fs-body);
   width: 100%;
   color: var(--color-text-primary);
}

@media screen and (min-width: 769px) and (max-width: 1200px) {

   .aboutUs-pictures-container {
      width: 50%;
      justify-content: flex-end;
   }
   .aboutUs-image-container {
      gap: 5px;
      min-height: max-content;
      flex-direction: column;
   }
   

   .aboutUs-image:first-child {
      max-height: unset;
      height: auto;
      width: 70%;
      object-fit: contain;
   }
   
   .aboutUs-image:last-child {
      max-height: unset;
      height: auto;
      width: 70%;
      object-fit: contain;
   }

   .aboutUs-text-container {
      max-width: 60%;
      align-items: flex-start;
      gap: 15px;
   }

}

@media (max-width: 768px) {
   .aboutUs-first-container {
      flex-wrap: wrap;
   }

   .aboutUs-pictures-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
   }

   .aboutUs-image-container {
      width: 100%;
      height: 100%;
      gap: 0;
   }

   .aboutUs-image:first-child {
      height: auto;
      min-width: 40%;
      object-fit: contain;
      align-self: flex-end;
   }

   .aboutUs-image:last-child {
      height: auto;
      width: 60%;
      object-fit: contain;
      align-self: flex-start;
   }

   .aboutUs-text-container {
      max-width: 100%;
   }

   .aboutUs-paragraph {
      text-align: center;
   }
}


@media (max-width: 370px) {
   .aboutUs-image-container {
      gap: 5px;
      align-items: center;
      min-height: unset;
   }
   
   .aboutUs-image:first-child {
      align-self: unset;
   }

   .aboutUs-image:last-child {
      align-self: unset;
   }
}