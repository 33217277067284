.transfer-bank-page__section {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--layout-padding-y) var(--layout-padding-x);
}

.transfer-bank-page__section--noPadding {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.transfer-bank-page--content {
    gap: 10%;
    align-items: flex-start;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .transfer-bank-page--content {
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {
    .transfer-bank-page__section .sectionTitle-container {
        align-items: center;
    }

    .transfer-bank-page__section .sectionTitle-container .sectionTitle-downText {
        text-align: center;
    }

    .transfer-bank-page--content {
        flex-direction: column;
    }
}