.teamBubbles-container {
   position: relative;
   width: 100%;
   max-width: 768px;
   display: flex;
   justify-content: center;
   flex-wrap: wrap;
   margin: 0 auto;
}

.withMinHeight {
   max-height: 500px;
   min-height: 500px;
   margin-bottom: 40px;
}

@media screen and (min-width: 501px) and (max-width: 768px) {
   .withMinHeight {
      max-height: 450px;
      min-height: 450px;
   }
}

@media (max-width: 500px) {
   .withMinHeight {
      max-height: 550px;
      min-height: 550px;
   }
}