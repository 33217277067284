.bank-accounts-container{
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   flex-grow: 1;
   gap: 30px;
}

.bank-accounts--anyAccount{
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.bank-accounts--anyAccount p{
   font-size: var(--fs-body);
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
   .bank-accounts-container{
      width: 100%;
      padding: 80px 0;
   }
}

@media screen and (max-width: 768px) {
   .bank-accounts-container{
      width: 100%;
      padding: 80px 0;
   }
}