.navbar {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: var(--headerHeight);
   background-color: transparent;
   transition: all .3s;
   z-index: 100;

   display: grid;
   place-items: center;
   padding: var(--mt);
}


.navbar .nav-container {

   display: grid;
   grid-template-columns: repeat(3, 1fr);
   justify-items: start;
   width: 100%;
   max-width: calc(var(--maxWidthContainer) - calc(var(--mt) * 2));
   height: 100%;
   padding: 0 var(--mt);
}

.navbar.active .nav-container {
   background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
   backdrop-filter: blur(20px);
   -webkit-backdrop-filter: blur(10px);
   border-radius: 50px;
   box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}

.logo-container {
   display: flex;
   align-items: center;
}

.logo-container img {
   max-height: 30px;
   filter: grayscale(0) !important;
}

.navbar .navbar-navlinks-container {
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 20px;
   height: 100%;
}

.navLink {
   display: flex;
   align-items: center;
   cursor: pointer;
   font-size: var(--fs-body);
   background-color: transparent;
   border: none;
   text-transform: uppercase;
   color: var(--color-text-primary);
   text-decoration: none;
   text-wrap: nowrap;
}

.navLink:focus {
   outline: none;
}

.navLink:hover {
   color: var(--color-tertiary);
}

.dropdown-parent {
   padding: 10px;
   position: relative;
   height: 100%;
   display: flex;
   align-items: center;
}

.dropdown-chevron-group {
   display: flex;
   align-items: center;
   color: var(--color-text-primary);
}

.dropdown-chevron-group:hover {
   color: var(--color-tertiary);
}

.subMenuDropdown {
   position: absolute;
   display: flex;
   flex-direction: column;
   left: 0;
   top: 100%;
   box-shadow: -1px 1px 3px #00000077;
   background-color: var(--color-white);
   padding: 15px;
   border-radius: var(--border-radius-lg);
}

.dropdown-title {
   font-size: var(--fs-h4);
   font-weight: normal;
   margin-bottom: 20px;
}

.subMenuDropdown-level {
   position: absolute;
   left: 100%;
   top: 0;
   background-color: var(--color-white);
   padding: 10px;
   box-shadow: -1px 1px 3px #00000077;
}

.subMenuDropdown-level .navLink {
   padding: 10px;
}

.dropdown-active {
   display: flex;
}

.dropdown-inactive {
   display: none !important;
}

.burger-btn {
   display: none;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
   .navbar {
      padding: var(--mt) calc(var(--mt) / 2);
   }

   .navbar .navbar-navlinks-container {
      gap: 10px;
      padding-left: 0;
      flex-grow: 1;
      /* justify-content: flex-end; */
   }

   .logo-container img {
      height: auto;
      width: 90px;
      object-fit: cover;
   }
}

@media screen and (min-width: 769px) and (max-width: 1050px) {

   .navbar .nav-container {
      grid-template-columns: 100px 1fr 1fr;
      justify-items: start;
   }

}

@media (max-width: 768px) {

   .navbar .nav-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
   }

   .navbar.active {
      padding: 0;
   }

   .navbar.active .nav-container {
      border-radius: 0;
   }

   .navbar .navbar-toggle-inactive {
      position: absolute;
      top: -100vh;
      left: 0;
      height: calc(100vh - var(--headerHeight));
      width: 100%;
      transition: all .3s;
      background-color: var(--color-white);
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
   }

   .navbar .navbar-toggle-active {
      position: absolute;
      top: var(--headerHeight);
      left: 0;
      padding: var(--px);
      width: 100%;
      height: 100vh;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      background-color: var(--color-primary-dark);
      transition: all .3s;
   }

   .burger-btn {
      display: block;
      
   }

}