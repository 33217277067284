.mega-menu-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background-color: var(--color-background);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--box-shadow-strong);
}

.mega-menu-section-container {
    display: flex;
    width: 100%;
    height: 100%;
}

.mega-menu-section-heading {
    display: flex;
    width: 40%;
    padding: 20px;
}

.mega-menu-buttons {
    width: 100%;
    height: auto;
    padding: 0 20px 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
}

@media screen and (max-width: 768px) {
    .mega-menu-section-container {
        background-color: unset;
        border-radius: unset;
    }

    .mega-menu-section-heading {
        width: 100%;
        padding: 0;
    }
}