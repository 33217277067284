.courseHero-div-father-container {
   /* background-color: var(--color-primary-lightest); */
   height: auto;
   width: 100%;
   padding: calc(var(--layout-padding-y)*2) var(--layout-padding-x);
   /* box-shadow: var(--box-shadow); */
}

.courseHero-div-father-wrapper {
   display: flex;
   z-index: 1;
}

.courseHero-all-content-container-div {
   display: flex;
   width: 100%;
   z-index: 1;
   justify-content: space-between;
}

.courseHero-title-and-description-div {
   display: flex;
   /* flex: 1; */
   flex-direction: column;
   align-items: flex-start;
   justify-content: center;
   flex-grow: 1;
   gap: 10px;
   /* margin-right: 32px; */
}

.courseHero-title-h2-styling {
   display: flex;
   flex-direction: column;
   gap: 8px;
   flex-wrap: wrap;
   font-size: var(--fs-h2);
   line-height: calc(var(--fs-h2) * 1.2);
   color: var(--color-text-primary);
   margin-bottom: 24px;
   text-transform: uppercase;
}

.courseHero-title-h2-styling--outline {
   font-family: var(--font-secondary);
   color: transparent;
   -webkit-text-stroke: 1px var(--color-text-primary);
   text-stroke: 1px var(--color-text-primary);
}

.courseHero-paragraph-p-styling {
   font-size: var(--fs-body);
   color: var(--color-text-primary);
}

.courseHero-buttons-div {
   display: flex;
   align-self: flex-start;
   justify-self: flex-start;
   width: 100%;
}

.courseHero-image-container {
   width: auto;
   display: flex;
   justify-content: flex-end;
   align-items: flex-end;
}

@media (max-width: 1200px) {
   .courseHero-title-h1-styling {
      font-size: var(--fs-h2);
      line-height: calc(var(--fs-h2) * 1.2);
   }

   .courseHero-paragraph-p-styling {
      font-size: var(--fs-body);
   }

   .courseHero-image-container {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 30%;
   }

   .courseHero-image-container .imageOverShape-container {
      width: 100%;
   }
}

@media (max-width: 768px) {
   .courseHero-image-container {
      display: none;
   }

   .courseHero-title-and-description-div {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-right: 0;
   }

   /* .courseHero-div-father-container {
    background-color: var(--color-primary-lightest);
  } */

   .courseHero-title-and-description-content-div {
      margin-bottom: 32px;
   }
}