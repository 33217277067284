.bubble-item-container:hover{
   z-index: 10;
}

.bubble-item-container:hover img{
   filter: grayscale(0) !important;
}

.transform-48px:hover .bubble-item-container{
   transform: scale(2.2321);
}

.transform-48px:hover {
   transform: scale(2.2321);
   .bubble-overlay-heading h5{
      font-size: 6px;
   }
}

.transform-56px:hover .bubble-item-container{
   transform: scale(2.2321);
}

.transform-56px:hover {
   transform: scale(2.2321);
   .bubble-overlay-heading h5{
      font-size: 6px;
   }
}

.transform-72px:hover .bubble-item-container{
   transform: scale(1.5625);
}

.transform-72px:hover {
   transform: scale(1.5625);
   .bubble-overlay-heading h5{
      font-size: 8px;
   }
}

.transform-80px:hover .bubble-item-container{
   transform: scale(1.5625);
}

.transform-80px:hover {
   transform: scale(1.5625);
   .bubble-overlay-heading h5{
      font-size: 8px;
   }
}

.transform-88px:hover .bubble-item-container{
   transform: scale(1.4205);
}

.transform-88px:hover {
   transform: scale(1.4205);
   .bubble-overlay-heading h5{
      font-size: 10px;
   }
}
