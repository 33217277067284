.course-benefits-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
}

.course-benefits-heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

.course-benefits-description {
    width: 100%;
    color: var(--color-text-primary);
    font-size: var(--fs-body);
}

.course-benefits-information {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
}

.course-benefits-information article {
    width: 45%;
}

.course-benefits-icon {
    width: 32px;
    height: 32px;
    color: var(--color-text-secondary);
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .course-benefits-information article .icon-card-text-title {
        display: flex;
        flex-grow: 1;
    }
}

@media (max-width: 768px) {
    .course-benefits-container {
        gap: 30px;
        padding-right: 0;
    }

    .course-benefits-information {
        gap: 30px;
    }

    .course-benefits-information article {
        width: 100%;
        align-items: center;
    }

    .course-benefits-information article .icon-card-text-title {
        text-align: center;
    }

    .course-benefits-information article .icon-card-text-paragraph {
        text-align: center;
    }
}