.admin__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 24px 0;
}

.admin__content {
    display: grid;
    grid-template-columns: 15% 85%;
    grid-gap: 16px;
    height: 100%;
    width: 100%;
}

.admin__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

.admin__title {
    font-family: var(--font-primary);
    font-weight: 700;
    font-size: var(--fs-h2);
    color: var(--color-text-primary);
    text-align: center;
    margin-bottom: 24px;
}