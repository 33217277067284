.courseProfessorAvatar-first-section-div {
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
}

.courseProfessorAvatar-name-and-role-div-active {
  display: flex;
  width: 304px;
  transition: all 1s ease-in-out;
  overflow: hidden;
}

.courseProfessorAvatar-name-and-role-div-inactive {
  display: flex;
  width: 104px;
  overflow: hidden;
  transition: all 1s ease-in-out;
}

.courseProfessorAvatar-name-and-role {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
}

.courseProfessorAvatar-role-title-h4 {
  display: flex;
  align-self: flex-start;
  font-size: var(--fs-h4);
  color: var(--color-text-primary);
  font-weight: 700;
}

.courseProfessorAvatar-role-title-h5 {
  font-weight: 300;
  display: flex;
  align-self: flex-start;
  font-size: var(--fs-h5);
  color: var(--color-text-primary);
}
