.coursePaymentCard-father-div {
    display: flex;
    flex-direction: column;
    background-color: var(--color-primary-lightest);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--box-shadow);
    height: 100%;
    padding: 24px;
}

.coursePaymentCard-image-container {
    width: 50%;
}

.coursePaymentCard-image-styling {
    width: auto;
    max-width: 100%;
    border-radius: var(--border-radius-lg);
}

.coursePaymentCard-steps-container {
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 16px;
}

.coursePaymentCard-description-firstStep {
    font-size: var(--fs-body);
}

.coursePaymentCard-description-firstSpan {
    width: 43px;
    height: 43px;
    line-height: 43px;
    border-radius: 50%;
    text-align: center;
    font-size: var(--fs-body);
    background-color: #a5d8f3;
    border: 3px solid white;
    display: inline-block;
    margin: 0 8px;
}

.coursePaymentCard-description-secondSpan {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    text-align: center;
    font-size: var(--fs-body);
    border: 3px solid #a5d8f3;
    display: inline-block;
    margin: 0 8px;
}

.coursePaymentCard-paymentDetails-container {
    display: grid;
    grid-template-columns: 8fr 4fr;
    column-gap: 16px;
    margin: 8px 0;
}

.coursePaymentCard-price-value {
    font-size: var(--fs-h3);
    align-self: center;
    justify-self: flex-end;
}

.coursePaymentCard-course-title {
    font-size: var(--fs-h5);
    font-weight: 500;
}

.coursePaymentCard-firstHr-div-container {
    display: flex;
    width: 100%;
    padding: 8px 0;
}

.coursePaymentCard-form-styling {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    width: 100%;
}

.coursePaymentCard-textInput-div {
    width: 100%;
}

.coursePaymentCard-textInput-label {
    display: flex !important;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: var(--fs-small) !important;
}

.coursePaymentCard-error-message {
    color: red;
    font-size: var(--fs-tiny);
    margin-top: 4px;
}

.coursePaymentCard-firstButton-div {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.CoursePaymentCard--discount-btn:hover{
   opacity: .7;
}

.coursePaymentCard-firstHr-second-p-styling {
    margin-left: auto;
    font-size: var(--fs-small);
}

.coursePaymentCard-divisorHr-subtotal {
    display: flex;
    width: 100%;
    padding: 8px 0;
}

.coursePaymentCard-subtotal-p {
    font-size: var(--fs-small);
}

.coursePaymentCard-divisorHr-styling {
    border-top: 1px solid var(--color-secondary);
    height: 2px;
    padding: 0;
    margin: 4px 0;
}

.coursePaymentCard-secondHr-div-container {
    display: flex;
    width: 100%;
    padding: 8px 0;
}

.coursePaymentCard-total-p {
    font-size: var(--fs-small);
}

.coursePaymentCard-secondHr-second-p-styling {
    margin-left: auto;
    font-size: var(--fs-body);
}

.coursePaymentCard-selector-container {
    margin-top: 8px;
}

.coursePaymentCard-checkbox-label {
    font-size: var(--fs-small);
}

.coursePaymentCard-checkbox-description {
    font-size: var(--fs-tiny) !important;
}

.coursePaymentCard-thirdCheckbox-root {
    padding-bottom: 8px;
}

.coursePaymentCard-button-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
}

.coursePaymentCard-paypal-plans-container {
    display: flex;
    width: 100%;
    background-color: #43A4D4;
    align-items: center;
    justify-content: center;
    margin: -8px 0 16px 32px;
}

@media screen and (max-width: 1000px) {
    .coursePaymentCard-form-styling {
        grid-template-columns: 2fr 1fr;
    }
}