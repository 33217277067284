.aboutUsPage-values-section {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.aboutUsPage-values-section-heading {
    width: 100%;
    display: grid;
    place-items: center;
}

.aboutUsPage-values-section-heading h2 {
    color: var(--color-text-primary);
    font-weight: 600;
    font-size: var(--fs-h2);
}

.aboutUsPage-values-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 5%;
    row-gap: 30px;
}

.aboutUsPage-values-content-container .icon-card-text-container {
    align-items: center;
    width: 30%;
}

.aboutUsPage-values-content-container .icon-card-text-container .icon-card-text-paragraph {
    text-align: center;
}

.aboutUsPage-values-icon {
    height: 40px;
    width: 40px;
    color: var(--color-text-secondary);
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .aboutUsPage-values-content-container .icon-card-text-container {
        width: 40%;
    }
}

@media screen and (max-width: 768px) {
    .aboutUsPage-values-content-container .icon-card-text-container {
        width: 100%;
    }

    .aboutUsPage-values-content-container .icon-card-text-container .icon-card-text-paragraph {
        max-width: 40ch;
    }
}