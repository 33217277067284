.Catalog-main-wrapper .tinyDecorativeCircle,
.Catalog-main-wrapper .decorativeCircle
{
    filter: blur(14px);
}

.Catalog__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.Catalog__alternative {
    opacity: .5;
}

.Catalog__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    gap: 24px;
}

.Catalog__column {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 24px;
    margin-bottom: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Catalog__emailDiv{
    width: 100%;
    max-width: 400px;
}

.Catalog__title {
    font-family: var(--font-primary);
    font-size: var(--fs-h1);
    line-height: calc(var(--fs-h1)*1.2);
    color: var(--color-text-primary);
    margin-bottom: 0;
    text-align: center;
    text-transform: uppercase;

    font-family: var(--font-secondary);
    color: transparent;
    -webkit-text-stroke:2px var(--color-text-primary);
    text-stroke: 2px var(--color-text-primary);
}

.Catalog__text {
    font-family: var(--font-primary);
    font-size: var(--fs-body);
    line-height: calc(var(--fs-body)*1.5);
    color: var(--color-text-primary);
}

.Catalog__gradient {
    font-family: var(--font-primary);
    color: var(--color-text-primary);
    /* -webkit-text-stroke:2px var(--color-text-primary); */
    /* text-stroke: 2px var(--color-text-primary);  */
}

@media screen and (min-width: 769px) and (max-width: 1200px) {

    .Catalog__title {
        font-size: var(--fs-h2);
        line-height: calc(var(--fs-h2)*1.2);
    }

    .Catalog__text {
        font-size: var(--fs-small);
        line-height: calc(var(--fs-small)*1.5);
    }



}

@media screen and (max-width: 768px) {
    .Catalog__container {
        height: unset;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .Catalog__emailDiv{
        width: 100%;
        max-width: 300px;
    }

    .Catalog__text {
        text-align: center;
    }
}