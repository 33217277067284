.newsCard__wrapper {
    /*width: 100%;*/
    width: 1000px;
    height: 100%;

    border-radius: var(--border-radius-lg);
    overflow: hidden;
    filter: grayscale(50%);
    background-size: cover;

    transition: all 0.3s ease;
}

.newsCard__wrapper--true {
    opacity: 1;
    filter: grayscale(0%);

}
.newsCard__wrapper--false {
    opacity: 0.7;
    filter: grayscale(100%);
    transform: scale(0.9);
    
}



.newsCard__container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 48px;
    box-shadow: 0 200px 88px 0 rgba(0,0,0,0.75) inset;
}

.newsCard__title {
    font-family: var(--font-primary);
    font-size: var(--fs-h2);
    line-height: var(--fs-h2);
    font-weight: 700;
    color: var(--color-text-secondary);
    max-width: 80%;
    margin-bottom: 16px;
}
.newsCard__text {
    font-family: var(--font-primary);
    font-size: var(--fs-body);
    line-height: var(--fs-body);
    color: var(--color-text-secondary);
    max-width: 80%;
}

.newsCard__button {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

/*CAROUSEL CLASSES*/
.newsCarousel__container {
    width: 80%;
    margin-left: 10%;
}
.newsCarousel__root {
    padding: 24px;
    margin: 24px;
    width: 100%;
}


@media screen and (min-width: 1201px) and (max-width: 1300px) {
    .newsCard__wrapper {
        width: 800px;
    }
    /*CAROUSEL CLASSES*/
    .newsCarousel__container {
        width: 80%;
        margin-left: 10%;
    }
}
@media screen and (min-width: 1101px) and (max-width: 1200px) {
    .newsCard__wrapper {
        width: 700px;
    }
    /*CAROUSEL CLASSES*/
    .newsCarousel__container {
        width: 80%;
        margin-left: 10%;
    }
}
@media screen and (min-width: 1001px) and (max-width: 1100px) {
    .newsCard__wrapper {
        width: 600px;
    }
    /*CAROUSEL CLASSES*/
    .newsCarousel__container {
        width: 80%;
        margin-left: 10%;
    }
}

@media screen and (min-width: 901px) and (max-width: 1000px) {
    .newsCard__wrapper {
        width: 520px;
    }
    /*CAROUSEL CLASSES*/
    .newsCarousel__container {
        width: 80%;
        margin-left: 5%;
    }
    .newsCard__title {
        font-family: var(--font-primary);
        font-size: var(--fs-h3);
    }
}
@media screen and (min-width: 801px) and (max-width: 900px) {
    .newsCard__wrapper {
        width: 400px;
    }
    /*CAROUSEL CLASSES*/
    .newsCarousel__container {
        width: 80%;
        margin-left: 20%;
    }
    .newsCard__title {
        font-family: var(--font-primary);
        font-size: var(--fs-h3);
    }
}
@media screen and (min-width: 701px) and (max-width: 800px) {
    .newsCard__wrapper {
        width: 400px;
    }
    .newsCard__container {
        padding: 32px;
    }
    /*CAROUSEL CLASSES*/
    .newsCarousel__container {
        width: 80%;
        margin-left: 13%;
    }
    .newsCard__title {
        font-family: var(--font-primary);
        font-size: var(--fs-h3);
    }
}
@media screen and (min-width: 601px) and (max-width: 700px) {
    .newsCard__wrapper {
        width: 380px;
    }
    .newsCard__container {
        padding: 24px;
    }
    /*CAROUSEL CLASSES*/
    .newsCarousel__container {
        width: 80%;
        margin-left: 5%;
    }
    .newsCarousel__root {
        padding: 8px;
        margin: 8px;
        width: 100%;
    }
    .newsCard__title {
        font-family: var(--font-primary);
        font-size: var(--fs-h3);
    }
}

@media screen and (min-width: 501px) and (max-width: 600px) {
    .newsCard__wrapper {
        width: 304px;
    }
    .newsCard__container {
        padding: 24px;
    }
    /*CAROUSEL CLASSES*/
    .newsCarousel__container {
        width: 80%;
        margin-left: 5%;
    }
    .newsCarousel__root {
        padding: 8px;
        margin: 8px;
        width: 100%;
    }
    .newsCard__title {
        font-family: var(--font-primary);
        font-size: var(--fs-h3);
    }
}

@media screen and (max-width: 500px) {
    .newsCard__wrapper {
        width: 100%;
    }
    .newsCard__container {
        padding: 24px;
    }
    /*CAROUSEL CLASSES*/
    .newsCarousel__container {
        width: 80%;
        margin-left: 5%;
    }
    .newsCarousel__root {
        padding: 0;
        margin: 8px 0;
        width: 100%;
    }
    .newsCard__title {
        font-family: var(--font-primary);
        font-size: var(--fs-h3);
    }
}
