.admin__form__title {
    font-family: var(--font-primary);
    font-weight: 700;
    font-size: var(--fs-h2);
    color: var(--color-text-primary);
    text-align: center;
    margin-bottom: 24px;
}

.admin__form__form {
    background-color: var(--color-primary-light);
    width: auto;
    height: auto;
    padding: 32px;
    border-radius: var(--border-radius-md);
}

.admin__form__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
}