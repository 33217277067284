.aboutUsPage-sponsor-heading {
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 20px;
}

.aboutUsPage-sponsor-heading-paragraph-container {
   display: flex;
   flex-direction: column;
   width: 100%;
}

.aboutUsPage-sponsor-heading-text{
   color: var(--color-text-primary);
   font-size: var(--fs-body);
}