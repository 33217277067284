.coursesOffers-pagination-div-container {
  margin-top: 32px;
}

.course-offers-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.coursesOffers-pagination-div-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
