.testimonialGrid-grid-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.testimonialGrid-grid-container{
    width: 100%;
    max-height: 580px;
    overflow: hidden;
    mask-image: var(--mask);
    -webkit-mask-image: linear-gradient(0deg, transparent 3px, white 32px);
}