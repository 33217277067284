.transfer-bank-send-options-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
}

.transfer-bank-send-options-headline {
    font-size: var(--fs-body);
}

.transfer-bank-send-options--column {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.transfer-bank-options-fileInput-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    background-color: var(--color-primary-lightest);
    padding: 20px;
    border-radius: var(--border-radius-lg);
    box-shadow: var(--box-shadow);
    flex-shrink: 2;
}

.transfer-bank-options-others-container {
    display: flex;
    align-items: center;
    gap: 40px;
}

.transfer-bank-options-others-row {
    display: flex;
    align-items: center;
    gap: 40px;
}

.transfer-bank-options-manual-container{
   display: flex;
   flex-direction: column;
   width: 100%;
   gap: 20px;
   padding: 20px;
   outline: 1px solid var(--color-primary-lightest);
   border-radius: var(--border-radius-lg);
   box-shadow: var(--box-shadow);
}

.transfer-bank-options-manual-container p{
   font-size: var(--fs-body);
   text-align: center;
   color: var(--color-text-primary);
}

@media screen and (max-width: 768px) {
    .transfer-bank-options-others-container {
        justify-content: center;
        gap: 20px;
    }
}

@media screen and (max-width: 320px) {
    .transfer-bank-options-others-container {
        flex-direction: column;
        align-items: center;
    }
}
