.aboutUsPage-hero-container {
   /* background-color: var(--color-primary-lightest); */
   /* box-shadow: var(--box-shadow); */
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
   .aboutUsPage-hero-container .specialties-hero-container{
      flex-direction: column;
      gap: 50px;
   }
}

.aboutUsPage-hero-container .specialties-hero-container .specialties-hero-heading{
   padding-bottom: 50px;
}

@media screen and (max-width: 768px) {
   .aboutUsPage-hero-container .specialties-hero-container .specialties-hero-imageOver-container{
      display: none;
   }
}