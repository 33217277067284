.contact-section {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--layout-padding-y) var(--layout-padding-x);

    margin: 64px 0;
}

/* Pantallas pequeñas */
@media screen and (max-width: 768px) {
    .contact-section {
        margin: 24px 0 64px;
    }
}