.navbar__navbar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    max-height: 100%;
    width: 15%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #FFF;
    padding: 16px;
}

.navbar__navbarMain {
    flex: 1;
}

.navbar__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: var(--fs-tiny);
    line-height: calc(var(--fs-tiny) * 1.2);
    background-color: var(--color-primary);
    color: #FFF;
    padding: 8px 16px;
    margin: 8px 0;
    border-radius: 4px;
    font-weight: 500;
}

.navbar__linkIcon {
    color: #FFF;
    margin-right: 16px;
    width: 25px;
    height: 25px;
}