.video-player-container {
    display: flex;
    width: 80%;
    padding-top: 24px;
    z-index: 1;
}

.videoPlayerSection-iframe-div-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 60%
}

.videoPlayerSection-stats-div-container {
    padding-left: 80px;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.videoPlayerSection-stats-number-styling {
    font-size: var(--fs-h2);
    color: var(--color-tertiary);
}

.videoPlayerSection-stats-description-styling {
    font-size: var(--fs-body);
    color: var(--color-text-primary);
}

@media screen and (max-width: 768px) {
    .video-player-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: 24px;
    }

    .videoPlayerSection-iframe-div-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%
    }

    .videoPlayerSection-stats-div-container {
        width: 100%;
        padding-left: 40px;
        padding-top: 16px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
    }

    .videoPlayerSection-map-styling-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-right: 32px;
    }
}