.cardPhotoBg-container{
   display: flex;
   position: relative;
   height: 100%;
   width: 100%;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   border-radius: var(--border-radius-lg);
   box-shadow: var(--box-shadow);
   overflow: hidden;
}

.cardPhotoBg-content{
   display: flex;
   flex-direction: column;
   gap: 10px;
   align-self: flex-end;
   max-height: 50%;
   width: 100%;
   background-color: #000000d2;
   border-bottom-left-radius:var(--border-radius-lg);
   border-bottom-right-radius:var(--border-radius-lg);
   padding: 15px;
}

.cardPhotoBg-rowGroup{
   width: 100%;
   display: flex;
   align-items: center;
}

.cardPhotoBg-title{
   font-size: var(--fs-h3);
   line-height: calc(var(--fs-h3) * 1.2);
}

.cardPhotoBg-description{
   font-size: var(--fs-small);
   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
}

.cardPhotoBg-tags-container{
   position: absolute;
   top: 15px;
   left: 15px;
   display: flex;
   flex-direction: column;
   gap: 10px;
}

.cardPhotoBg-tag{
   text-transform: uppercase;
   font-size: var(--fs-tiny);
   width: max-content;
   padding: 3px 20px;
   font-weight: 600;
   border-radius: var(--border-radius-lg);
   box-shadow: var(--box-shadow);
}

.cardPhotoBg-tag:nth-child(odd){
   background-color: var(--color-primary-dark);
}

.cardPhotoBg-tag:nth-child(even){
   background-color: var(--color-tertiary);
}



