.EliteCard-main-container {
   display: flex;
   flex-direction: column;
   gap: 32px;
   align-items: center;
   justify-content: flex-start;
   min-height: 99vh;
}

.EliteCard--title {
   font-size: var(--fs-h2);
   line-height: calc(var(--fs-h2) * 1.2);
   text-align: center;
   width: 100%;
   /*background: linear-gradient(to bottom, #FFD700, #000000);*/
   background:linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;

   background:linear-gradient(to right, #BF953F, #FCF6BA,#FBF5B7, #AA771C);
  background-size: cover;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.EliteCard--title-teacher{
   font-size: var(--fs-h3);
   color: var(--color-text-secondary);
}

.EliteCard-info-container {
   width: 50%;
   display: flex;
   flex-direction: column;
   gap: 32px;
   align-items: flex-start;

}

.EliteCard--starIcon {
   color: rgb(199, 199, 98);
}

.EliteCard-item-label {
   font-size: var(--fs-h3);
   text-align: left;
}

.EliteCard--description {
   font-size: var(--fs-body);
   text-align: left;
   color: var(--color-text-secondary);
}


.EliteCard--price {
   font-size: var(--fs-h2);
   text-align: left;
   color: var(--color-text-secondary);
}

.EliteCard-main-container video {
   max-height: 99vh;
}

.EliteCard-image-container {
   width: 100%;
   display: grid;
   border-radius: var(--border-radius-lg);
}

.EliteCard--image {
   height: 100%;
   width: 100%;
   max-height: 400px;
   object-fit: cover;
   border-radius: var(--border-radius-lg);
}


.EliteCard-schedule-container {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 8px;
}

.EliteCard-schedule-wrapper {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   gap: 16px;
   flex-wrap: wrap;
}

.EliteCard-schedule-item {
   display: flex;
   align-items: center;
   gap: 8px;
   line-height: 1;
   min-width: 60px;
   text-align: center;
   font-size: var(--fs-body);
   padding: 8px 12px;
   background-color: transparent;
   outline: 1px solid var(--color-yellow);
   color: var(--color-yellow);
   border-radius: var(--border-radius-lg);
}

.EliteCard-schedule--icon {
   flex-shrink: 0;
}

.EliteCard-schedule-startDate {
   font-size: var(--fs-tiny);
}


.EliteCard-teacher-wrapper {
   width: 100%;
   overflow-x: scroll;
   padding-left: 20px;
   padding-bottom: 8px;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   gap: 56px;
   flex-wrap: nowrap;
}

.EliteCard-teacher-wrapper::-webkit-scrollbar {
   height: 16px;
}


.EliteCard-teacher-wrapper::-webkit-scrollbar-thumb {
   background-color: #8888888e;
   border-radius: 10px;
}

.EliteCard-teacher-wrapper::-webkit-scrollbar-thumb:hover {
   background: #555;
}

.EliteCard-teacher-scroll-text {
   font-size: var(--fs-tiny);
}

.VerticalCard-main-container {
   cursor: pointer;
   flex-shrink: 0;
   width: 400px;
   height: 400px;
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
   background-size: cover;
   border-radius: var(--border-radius-md);
   background-position: center;
   transition: opacity .3s;
}

.VerticalCard-main-container:hover{
   opacity: .8;
   transition: opacity .3s;
}

.VerticalCard--background-image-container {
   width: 100%;
   height: 100%;
   position: absolute;
   top: 0;
   left: 0;
   border-radius: var(--border-radius-md);
   object-fit: cover;
   z-index: 1;
   filter: blur(3px) brightness(70%);
   display: grid;
   place-items: center;

}

.VerticalCard--background-image {
   width: 95%;
   height: 95%;
   border-radius: var(--border-radius-md);
   object-fit: cover;
}

.VerticalCard--image {
   width: 80%;
   height: 85%;
   object-fit: cover;
   border-radius: var(--border-radius-md);
   z-index: 2;


}

.VerticalCard--name {
   position: absolute;
   letter-spacing: 4px;
   font-size: var(--fs-body);
   color: var(--color-text-secondary);
   font-weight: 300;
   writing-mode: vertical-rl;
   left: -25px;
   bottom: var(--border-radius-lg);
   transform: rotate(180deg);
}

.EliteCard-TeacherModal--name {
   font-size: var(--fs-h3);
   color: var(--color-text-secondary);
}

.EliteCard-TeacherModal--description {
   font-size: var(--fs-body);
   color: var(--color-text-secondary);
   max-width: 40ch;
}

.EliteCard-TeacherModal--group {
   display: flex;
   align-items: center;
   gap: 8px;
}

.EliteCard-TeacherModal-locationWrapper {
   width: 100%;
   display: flex;
   align-items: flex-start;
   flex-wrap: wrap;
   gap: 16px;
}

.EliteCard-TeacherModal-locationText {
   font-size: var(--fs-small);
}

.EliteCard-TeacherModal--image {
   width: auto;
   max-width: 350px;
   height: 40vh;
   object-fit: cover;
   border-radius: var(--border-radius-lg);
}

.EliteCard-TeacherModal--personalItemLabel,
.EliteCard-TeacherModal--personalItemValue {
   font-size: var(--fs-body);
   color: var(--color-text-secondary);
   font-weight: 400;
}

.EliteCard-TeacherModal--personalItemValue {
   font-size: var(--fs-small);
   opacity: .8;
}

@media (max-width: 992px) {
   .EliteCard-main-container video {
      max-width: 500px;
   }
}

@media (max-width: 768px) {
   .EliteCard-wrapper-container {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
   }

   .EliteCard-image-container {
      order: -1;
      width: 100%;
   }

   .EliteCard--title,.EliteCard--title-teacher {
      font-size: var(--fs-h4);
   }

   .EliteCard-info-container {
      width: 100%;
   }

   .EliteCard-main-container video {
      max-width: calc(100vw - 1rem);
      object-fit: cover;
   }

   .EliteCard-TeacherModal-image-container {
      order: -1;
   }

   .EliteCard-TeacherModal--image {
      width: 100%;
      max-width: unset;
      height: auto;
   }

}