.coursesLandingHero-father-div-container {
   /* background-color: var(--color-primary-lightest); */
   height: auto;
   width: 100%;
   padding-top: 40px;
   padding-bottom: 40px;
   display: flex;
   /* box-shadow: var(--box-shadow); */
}

.coursesLandingHero-content-wrapper {
   display: flex;
   z-index: 1;
}

.coursesLandingHero-image-container {
   width: auto;
   padding-right: 2%;
   display: flex;
   justify-content: flex-start;
   align-items: center;
}

.coursesLandingHero-all-content-container-div {
   display: flex;
}

.coursesLandingHero-title-and-description-div {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: flex-start;
   padding-right: 20%;
}

.coursesLandingHero-title-and-description-content-div {
   margin-bottom: 48px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   text-align: start;
}


.coursesLandingHero-title-styling {
   display: flex;
   flex-direction: column;
   gap: 8px;
   flex-wrap: wrap;
   font-size: var(--fs-h2);
   line-height: calc(var(--fs-h2) * 1.2);
   color: var(--color-text-primary);
   margin-bottom: 24px;
   text-transform: uppercase;
}

.coursesLandingHero-title-styling--outline:nth-child(1),
.coursesLandingHero-title-styling--outline:nth-child(2),
.coursesLandingHero-title-styling--outline:nth-child(3),
.coursesLandingHero-title-styling--outline:nth-child(4),
.coursesLandingHero-title-styling--outline:nth-child(5),
.coursesLandingHero-title-styling--outline:nth-child(6)
{
   font-family: var(--font-secondary);
   color: transparent;
   -webkit-text-stroke: 1px var(--color-text-primary);
   text-stroke: 1px var(--color-text-primary);
}

.coursesLandingHero-paragraph-p-styling {
   font-size: var(--fs-body);
   color: var(--color-text-primary);
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
   .coursesLandingHero-title-and-description-div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-right: 0;
   }

   .coursesLandingHero-title-h1-styling {
      font-size: var(--fs-h2);
      line-height: calc(var(--fs-h2) * 1.2);
   }

   .coursesLandingHero-paragraph-p-styling {
      font-size: var(--fs-body);
      padding: 0;
   }

   .coursesLandingHero-image-container {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
   }
}

@media screen and (max-width: 768px) {
   .coursesLandingHero-image-container {
      display: none;
   }

   .coursesLandingHero-title-and-description-div {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding-right: 0;
   }

   .coursesLandingHero-title-and-description-content-div {
      margin-bottom: 48px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
   }

   .coursesLandingHero-father-div-container {
      padding: var(--layout-padding-y) var(--layout-padding-x);
   }
}