.footer-info-container {
   width: 100%;
   max-width: var(--maxWidthContainer);
   margin: 0 auto;
   display: flex;
   align-items: flex-start;
   justify-content: center;
   border-bottom: 1px solid var(--gray);
   padding: var(--py) var(--px);
}

.footer-contact-container,
.footer-socials-container,
.footer-logo-container {
   display: flex;
   width: 30%;
   align-items: center;
   justify-content: center;
}

.footer-contact-container {
   flex-grow: 1;
}

.footer-contact-container .footer-column-container {
   width: max-content;
}

.footer-column-container {
   width: 80%;
   display: flex;
   flex-direction: column;
   gap: 15px;
}

.footer-logo-image {
   width: 100%;
   height: auto;
}

.footer-logo-golden, .footer-logo-image{
   filter: grayscale(0) !important;
}

.footer-logo-golden{
   height: 35px;
   width: auto;
   object-fit: contain;
}

.footer-item-container {
   display: flex;
   align-items: center;
   gap: 10px;
   width: max-content;
}

.footer-item-container:hover .footer-item-text {
   color: var(--color-tertiary);
   transition: all .3s;
}

.footer-item-container:hover .footer-item-icon {
   color: var(--color-tertiary);
   transition: all .3s;
}

.footer-column-title {
   font-size: var(--fs-body);
   font-weight: 700;
   margin: 0;
}

.footer-item-text {
   font-size: var(--fs-small);
   color: var(--color-text-tertiary);
   transition: all .3s;
}

.footer-item-icon {
   height: 20px;
   width: 20px;
   color: var(--color-text-secondary);
}

@media (max-width: 768px) {
   .footer-info-container {
      flex-wrap: wrap;
      border: none;
      gap: 30px;
      align-items: center;
      z-index: 1;
   }

   .footer-contact-container,
   .footer-socials-container,
   .footer-logo-container {
      width: 100%;
   }

   .footer-column-container {
      display: flex;
      flex-direction: column;
   }

   .footer-column-title {
      text-align: center;
   }

   .footer-contact-container{
      flex-grow: unset;
   }

   .footer-contact-container .footer-column-container {
      width: 70%;
   }

   .footer-socials-container .footer-column-container {
      width: 70%;
   }


   .footer-logo-image {
      width: 100%;
   }
}