.course-requirements-container{
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 16px;
   background-color: var(--color-primary-dark);
   padding: var(--layout-padding-y) var(--layout-padding-x);
   /*Dirty adjustments to make background overflow behind card*/
   width: calc(100% + var(--layout-padding-x) + 30%);
   margin-right: -30%;
   padding-right: 30%;
}

.course-requirements-container .sectionTitle-downText{
   color: var(--color-text-secondary);
}

.course-requirements-description{
   font-size: var(--fs-body);
   width: 100%;   
}

@media (max-width: 768px) {
   .course-requirements-container{
      width: calc(100% + var(--layout-padding-x) + 30%);
      margin-right: calc( -2 * var(--layout-padding-x));
      padding-right: var(--layout-padding-x);
   }
   .course-requirements-description{
      font-size: var(--fs-body);
      width: 100%;   
   }
}