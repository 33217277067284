.transfer-bank-cart-container {
   position: relative;
   display: flex;
   flex-direction: column;
   /* width: 100%; */
   flex-grow: 1;
   max-width: 50%;
   gap: 10px;
   border-radius: var(--border-radius-lg);
   box-shadow: var(--box-shadow);
   background-color: var(--color-primary-lightest);
}

.transfer-bank-cart-header {
   display: flex;
   width: 100%;
   gap: 10px;
   background-color: var(--color-primary-light);
   border-top-left-radius: var(--border-radius-lg);
   border-top-right-radius: var(--border-radius-lg);
}

.transfer-bank-cart-image-container {
   display: flex;
   min-height: 100%;
   width: 40%;
   min-width: 100px;
}

.transfer-bank-cart-info {
   display: flex;
   justify-content: flex-start;
   gap: 10%;
   padding: 16px;
}

.transfer-bank-cart-info--group {
   display: flex;
   align-items: center;
   gap: 10px;
   font-size: var(--fs-body);
}

.transfer-bank-cart-info--text{
   font-weight: 300;
   color: var(--color-text-secondary);
   text-wrap: balance;
}

.transfer-bank-cart--image {
   max-height: 150px;
   height: 100%;
   width: 100%;
   object-fit: cover;
   border-top-left-radius: var(--border-radius-lg);
}

.transfer-bank-cart-info {
   font-size: var(--fs-body);
}

.transfer-bank-cart-info--icon {
   height: 30px;
   width: 30px;
   color: var(--color-secondary);
}

.transfer-bank-cart-buyName {
   padding-right: 10px;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
   .transfer-bank-cart-container {
      width: 100%;
      max-width: 800px;
   }
}

@media (max-width: 768px) {
   .transfer-bank-cart-container {
      width: 100%;
      max-width: 100%;
   }
   .transfer-bank-cart--image{
      max-height: unset;
   }
}

@media (max-width: 576px) {
   .transfer-bank-cart-info{
      flex-direction: column;
   }
}

@media screen and (max-width: 350px) {
   .transfer-bank-cart-info--group{
      overflow: hidden;
   }
   
   .transfer-bank-cart-buyName {
      padding-right: unset;
      text-overflow: ellipsis;
      text-wrap: balance;
      overflow: hidden;
   }
}