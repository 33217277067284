
.bubble-overlay-container{
   position: absolute;
   border-radius: 50%; 
   background-color: #0000008f;
   display: grid;
   place-items: center;
   gap: 4px;
   transition: all .3s;
   opacity: 0;
}

.bubble-item-container:hover .bubble-overlay-container{
   opacity: 1;
}

.bubble-overlay-heading{
   display: grid;
   place-items: center;
   width: 90%;
   color: var(--color-white);
}

.bubble-overlay-heading h5{
   text-align: center;
   font-size: 6px;
   transition: all .1s;
}

.bubble-overlay-socialMedia-container{
   display: flex;
   justify-content: center;
   gap: 4px;
}

.overlay-link-container{
   width: 15%;
   height: 15%;
   max-height: 24px;
   max-width: 24px;
   display: grid;
   place-items: center;
}

.overlay-link-container img{
   height: 100%;
   width: 100%;
}