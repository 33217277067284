.landing__section {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--layout-padding-y) var(--layout-padding-x);
}

.landing__section--noPadding {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.landingHero__circle {
    width: 600px;
    height: 600px;
    border: 100px solid var(--color-primary-dark);
    border-radius: 50%;
    background-color: transparent;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-40%, -60%);
    z-index: 0;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    /*NOTHING HERE*/
}
