.preguntasFrecuentesContainer-father-div {
    min-height: 650px;
  }
  
  .preguntasFrecuentesContainer-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 50px;
  }
  
  .preguntasFrecuentesContainer-div-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
    grid-row: 1;
    grid-column: span 4;
  }
  
  .preguntasFrecuentesContainer-content {
    grid-column: span 7;
    grid-row: 1;
  }
  
  .preguntasFrecuentesContainer-image {
    width: auto;
    height: 100%;
    max-width: 100%;
    border-radius: var(--border-radius-lg);
  }
  
  .preguntasFrecuentesContainer-title {
    font-size: var(--fs-h2);
    padding-bottom: 16px;
  }
  
  .preguntasFrecuentesContainer-accordion-labels {
    font-size: var(--fs-small) !important;
  }
  
  .preguntasFrecuentesContainer-accordion-panel {
    font-size: var(--fs-tiny) !important;
  }

  .preguntasFrecuentesContainer-contactCard-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-row: 2;
    grid-column: span 12;
  }

  .preguntasFrecuentesContainer-contactCard-text{
    font-size: var(--fs-h4);
    margin-top: -16px;
    margin-bottom: 16px;
  }




@media screen and (min-width: 769px) and (max-width: 1200px) {
    .preguntasFrecuentesContainer-div-image {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70%;
        grid-column: span 5;
      }
      
      .preguntasFrecuentesContainer-content {
        grid-column: span 7;
      }
}

@media screen and (max-width: 769px) {
    .preguntasFrecuentesContainer-father-div {
        width: 100%;
      }

      .preguntasFrecuentesContainer-grid {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

    .preguntasFrecuentesContainer-content {
        width: 100%;
      }

      .preguntasFrecuentesContainer-contactCard-div{
        width: 100%;
      }
}