.mainLayout__container {
    width: 100%;
    max-width: var(--maxWidthContainer);
    min-height: 100vh;
    margin: 0 auto;
    padding-top: var(--headerHeight);
    box-shadow: 0 0 24px 8px #0000000F;

    /* background-color: var(--color-primary); */

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.mainLayout__container main {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
}

.main-layout-section{
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: var(--layout-padding-y) var(--layout-padding-x);
}

.main-layout-section--noPadding {
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.main-layout-section:first-child{
   padding-top: var(--headerHeight);
}

.main-layout-hero--padding{
   padding: var(--layout-padding-y) var(--layout-padding-x) 0 var(--layout-padding-x);
}