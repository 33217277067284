.specialties-section {
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: var(--layout-padding-y) var(--layout-padding-x);
}

.specialties-hero-section {
    width: 100%;
   /* background-color: var(--color-primary-lightest); */
   box-shadow: var(--box-shadow);
}

.specialties-section--noPadding {
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}

/* .specialties-section .specialties-hero-container .specialties-hero-heading{
   padding-bottom: 50px;
} */

.last-section__paddingBtm{
   padding-bottom: 100px;
}


@media screen and (min-width: 769px) and (max-width: 1200px) {
   /*NOTHING HERE*/
}

@media screen and (max-width: 768px) {
   .specialties-hero-section .specialties-hero-container .specialties-hero-imageOver-container{
      display: none;
   }
}