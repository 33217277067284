.teamHeader-container{
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   gap: 15px;
}

.teamHeader-title{
   text-align: center;
   font-size: var(--fs-h2);
   color: var(--color-text-primary);
   font-weight: 700;
}

.teamHeader-paragraph{
   font-size: var(--fs-body);
   text-align: center;
   width: 80ch;
   color: var(--color-text-primary);
}
@media screen and (min-width: 769px) and (max-width: 1200px) {
   .teamHeader-paragraph{
      width: 60%;
   }
}
@media (max-width: 768px) {
   .teamHeader-paragraph{
      width:100%;
   }
}
