.navLink {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: var(--fs-body);
    background-color: transparent;
    border: none;
    color: var(--color-text-primary);
    text-decoration: none;
    position: relative;
}

.navLink:focus {
    outline: none;
}

.navLink:hover {
    opacity: .7;
    color: var(--color-tertiary);
}

.navLink--elite{
   color: var(--color-text-secondary);
}

.navLink--elite:hover{
    color: #ffe3a7;
}

.mega-menu-chevron-group {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
}

.mega-menu-chevron-row {
    display: flex;
    gap: 0;
}

.mega-menu-chevron-row:hover {
    color: var(--color-tertiary);
}

.mega-menu-chevron-row--elite:hover{
   color: #ffe3a7;
}


@media screen and (min-width: 901px) and (max-width: 1200px) {
    .navLink {
        font-size: var(--fs-small);
    }
}

@media screen and (min-width: 769px) and (max-width: 900px) {
   .navLink {
       font-size: var(--fs-tiny);
   }
}

@media (max-width: 768px) {
    .mega-menu-chevron-group {
        height: unset;
        flex-direction: column;
        align-items: flex-start;
    }

    .mega--chevron-group .navLink {
        padding: 0;
    }

    .navLink {
        background-color: transparent;
        border: none;
        color: var(--color-text-secondary);
    }
}