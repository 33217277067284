.courseProfessorInfo-note {
    font-size: var(--fs-small);
    text-align: center;
    margin: 24px 0;
    color: var(--color-text-tertiary);
}

.courseProfessorInfo-carousel-div-container {
    width: 100%;
    margin-top: 48px;
    max-width: 1000px;
}

/*TODO: review after merge issue*/
.courseProfessorInfo-mantine-carousel-root-styling {
    width: 100%;
    margin: 0 8px;
    padding: 8px;
    display: flex;
    align-items: center;
}

.courseProfessorInfo-mantine-carousel-slide-styling {
    width: 100%;
    margin: 0 8px;
    padding: 8px;
    display: flex;
    align-items: center;
}

@media screen and (min-width: 1401px) and (max-width: 1500px) {
  .courseProfessorInfo-carousel-div-container {
    max-width: 904px;
  }
}
@media screen and (min-width: 1301px) and (max-width: 1400px) {
  .courseProfessorInfo-carousel-div-container {
    max-width: 840px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1301px) {
  .courseProfessorInfo-carousel-div-container {
    max-width: 768px;
  }
}
@media screen and (min-width: 1101px) and (max-width: 1201px) {
  .courseProfessorInfo-carousel-div-container {
    max-width: 640px;
  }
}
@media screen and (min-width: 1001px) and (max-width: 1101px) {
  .courseProfessorInfo-carousel-div-container {
    max-width: 584px;
  }
}
@media screen and (min-width: 901px) and (max-width: 1001px) {
  .courseProfessorInfo-carousel-div-container {
    max-width: 520px;
  }
}
@media screen and (min-width: 801px) and (max-width: 901px) {
  .courseProfessorInfo-carousel-div-container {
    max-width: 464px;
  }
}
@media screen and (min-width: 769px) and (max-width: 801px) {
  .courseProfessorInfo-carousel-div-container {
    max-width: 400px;
  }
}

@media screen and (min-width: 501px) and (max-width: 769px) {
  .courseProfessorInfo-carousel-div-container {
      max-width: 90vw;
  }
}

@media screen and (max-width: 501px) {
  .courseProfessorInfo-carousel-div-container {
      max-width: 80vw;
  }
}
