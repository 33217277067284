

.GlassCard-main-container {
   width: 250px;
   height: 100%;
   min-height: 300px;
   background: rgba(255, 255, 255, 0.2);
   border-radius: var(--border-radius-lg);
   backdrop-filter: blur(10px);
   -webkit-backdrop-filter: blur(10px);
   box-shadow:var(--box-shadow);
   display: flex;
   justify-content: center;
   cursor: pointer;
   align-items: center;
}

.GlassCard-main-container:hover img {
   filter: grayscale(0%) !important;
}

.GlassCard-main-container:hover .GlassCard-title {
   opacity: .9;
   background-color: var(--color-text-primary);
   color: var(--color-text-secondary);
}

.GlassCard-wrapper-container {
   position: relative;
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 20px;
   justify-content: space-between;
   padding: 10px;
   border-radius: var(--border-radius-lg);
   background-position: center;
   background-size: cover;
}

.GlassCard--image{
   width: 100%;
   height: 100%;
   position: absolute;
   left: 0;
   top: 0;
   /* filter:brightness(40%); */
   z-index: -1;
   object-fit: cover;
   border-radius: var(--border-radius-lg);
}

.GlassCard-info-wrapper{
   display: flex;
   flex-grow: 1;
   justify-content: flex-start;
   align-items: flex-end;
   width: 100%;
}

.GlassCard-title {
   font-size: var(--fs-tiny);
   text-align: left;
   color: var(--color-text-secondary);
   width: fit-content;
   padding: 4px 8px;
   border-radius: var(--border-radius-md);
   background-color: var(--color-primary-lightest);
}

.GlassCard-wrapper-container p {
   text-align: center;
   font-size: var(--fs-small);
   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 3;
   line-clamp: 3;
   -webkit-box-orient: vertical;
}
