.tinyDecorativeCircle {
     border: 100px solid var(--color-tertiary);
     border-radius: 50%;
     background-color: transparent;
     opacity: 0.5;
     position: absolute;
     z-index: 0;
 }

.tinyDecorativeCircle__color-1 {
     border: 100px solid var(--color-tertiary);
}

.tinyDecorativeCircle__color-2 {
     border: 100px solid var(--color-secondary);
}

.tinyDecorativeCircle__color-3 {
     border: none;
     background: linear-gradient(45deg, var(--color-secondary-dark), var(--color-primary)); /* Aplica el gradiente como fondo */
}