.transferBankHero-container {
   width: 100%;
   z-index: 1;
   display: flex;
   gap: 10%;
   align-items: center;
}

.transferBankHero-container .sectionTitle-container {
   width: 40%;
}

@media screen and (max-width: 1200px) {
   .transferBankHero-container {
      gap: 30px;
      flex-direction: column;
      align-items: flex-start;
   }

   .transferBankHero-container .sectionTitle-container {
      width: 100%;
   }
}
