.createAUform__title {
    font-family: var(--font-primary);
    font-weight: 700;
    font-size: var(--fs-h3);
    color: var(--color-text-primary);
    text-align: center;
    margin-bottom: 24px;
}
.createAUform__subtitle {
    font-family: var(--font-primary);
    font-weight: 500;
    font-size: var(--fs-body);
    color: var(--color-text-primary);
    text-align: center;
    margin-bottom: 8px;
}
.createAUform__text {
    font-family: var(--font-primary);
    font-weight: 500;
    font-size: calc(var(--fs-body) - 4px);
    color: var(--color-text-primary);
    text-align: center;
    margin: 8px 0;
}

.createAUform__form {
    background-color: var(--color-primary-light);
    width: 1000px;
    height: auto;
    padding: 32px;
    border-radius: var(--border-radius-md);
    display: grid;
    align-items: center;
}

.createAUform__button {
    margin-top: 16px;
}

.createAUform__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--color-primary);
}

.createAUform__module__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 16px;
    border-radius: var(--border-radius-md);
    background-color: var(--color-primary-dark);
}

.createAUform__subscription__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 16px;
    border-radius: var(--border-radius-md);
    background-color: var(--color-primary-dark);
    margin: 8px 0;
}

.createAUform__lesson__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 16px;
    margin: 4px 0;
    border-radius: var(--border-radius-md);
    background-color: var(--color-primary-darkest);
}

.createAUform__module__wrapper {
    width: 99%;
    height: 100%;
    /*max-height: 800px;*/
    /*overflow-y: auto;*/
    padding: 16px;
    margin: 24px 0 8px;
}
.createAUform__lesson__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 24px 0 8px;
}

/*.createAUform__block__fields {*/
/*    display: grid;*/
/*    grid-template-columns: 1fr 1fr;*/
/*    grid-gap: 16px;*/
/*    width: 100%;*/
/*}*/