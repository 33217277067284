.courseProfessorInfo-second-section-div {
  width: 100%;
  margin-top: 16px;
}

.courseProfessorInfo-second-section-div-content-container {
  background-color: var(--color-primary-lightest);
  border-radius: var(--border-radius-md);
  box-shadow: var(--box-shadow);
}

.courseProfessorInfo-second-section-ul-list {
  padding: 32px 48px;
}

.courseProfessorInfo-second-section-ul-p {
  font-size: var(--fs-small);
}

.courseProfessorInfo-second-section-ul-p span {
  font-weight: 700;
}
/* Second section ends */

/* Third section starts */

.courseProfessorInfo-third-section-div {
  width: 100%;
  margin-bottom: 16px;
  margin-top: 16px;
}

.courseProfessorInfo-third-section-div-content-container {
  background-color: var(--color-primary-lightest);
  border-radius: var(--border-radius-md);
  box-shadow: var(--box-shadow);
  padding: 32px;
}

.courseProfessorInfo-third-section-title-h5 {
  margin-bottom: 16px;
  font-size: var(--fs-h5);
  color: var(--color-text-primary);
}

.courseProfessorInfo-third-section-p-content {
  font-size: var(--fs-small);
  color: var(--color-text-primary);
}

.courseProfessorInfo-fourth-section-title-h5 {
  font-size: var(--fs-h5);
  color: var(--color-text-primary);
}
