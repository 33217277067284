.coursesPage__section {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--layout-padding-y) var(--layout-padding-x);
}

.coursesPage__section--noPadding {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coursesPage-section-position-custom-coursesFilter {
  margin-top: -72px;
  margin-bottom: -24px;
  width: 100%;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .coursesPage-section-position-custom-coursesFilter {
    position: static;
  }
}

@media screen and (max-width: 768px) {
  .coursesPage-section-position-custom-coursesFilter {
    position: static;
  }

  .coursesPage-section-position-custom-coursesFilter {
    margin-top: -56px;
    width: 100%;
  }
}
