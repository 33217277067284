
.aval-image-wrapper {
    position: relative;
    top: 0;
    left: 0;
    width: 120px;
    height: 120px;
}

.aval-image-container {
    display: grid;
    place-items: center;

    position: absolute;
    top: 16px;
    left: 8px;
}

.aval-image-circle-blue {
    position: absolute;
    top: 8px;
    left: 0;

    width: 104px;
    height: 104px;
}

.aval-image-circle-pink {
    position: absolute;
    top: -4px;
    right: 8px;

   width: 64px;
   height: 64px;
}

.aval-image {
    height: 100%;
    max-height: 90px;
    width: auto;
    object-fit: cover;
    filter: grayscale(100%);
    transition: all .3s;
    cursor: pointer;
}

.aval-image:hover {
    filter: grayscale(0%);
    transition: all .3s;
}

@media (max-width: 768px) {
    .aval-image {
        max-height: 64px;
    }
    .aval-image-container {
        display: grid;
        place-items: center;

        position: absolute;
        top: 16px;
        left: 8px;
    }

    .aval-image-circle-blue {
        position: absolute;
        top: 8px;
        left: 0;

        width: 80px;
        height: 80px;
    }

    .aval-image-circle-pink {
        position: absolute;
        top: 0;
        right: 32px;

        width: 48px;
        height: 48px;
    }
}