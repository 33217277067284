.course__layout {
    display: grid;
    grid-template-columns: 7fr 3fr;
    grid-template-areas: "hero hero"
                        "main aside";
    grid-gap: 24px;
    width: 100%;
    height: 100%;
}

.course__main {
    grid-area: main;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: var(--layout-padding-x);
}

.course__aside {
    grid-area: aside;
    width: 100%;
    height: 100%;
    padding-right: var(--layout-padding-x);

    margin-top: -120px;
    z-index: 1;
}

.course__section {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--layout-padding-y) 0;
}

.course__section--noPadding {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: calc(-1 * var(--layout-padding-x));
}

.course__hero {
    grid-area: hero;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.course__buttons {
    display: none;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .course__layout {
        grid-template-columns: 7fr 4fr;
    }
}

@media screen and (max-width: 768px) {
    .course__main {
        padding: 0 var(--layout-padding-x);
    }
    .course__layout {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas:  "hero"
                              "aside"
                              "main";
        grid-gap: 24px;
        width: 100%;
        height: 100%;
        padding: 0;
    }

    .course__aside {
        margin-top: 0;
        padding: 0 var(--layout-padding-x);
    }

    .course__buttons {
        background-color: var(--color-primary-lightest);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        padding: 16px;
        box-shadow: 0 -4px 16px 0 rgba(0, 0, 0, 0.15);

        position: fixed;
        bottom: 0;
        z-index: 2;
    }
}